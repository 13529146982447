import axios from "axios";

import { ActionStatus } from "../types/ActionStatus.enum";
import { ActionType } from "../types/ActionType.enum";
import { ApiGetRequestsResponse } from "../types/ApiGetRequestsResponse.interface";
import { ApiGetTasksResponse } from "../types/ApiGetTasksResponse.interface";
import { ApiGetValidatorsResponse } from "../types/ApiGetValidatorsResponse.interface";
import {
  AssetUpdate,
  L1TransactionIndexer,
  L2TransactionIndexer,
  RequestInterface,
} from "../types/Request.interface";
import { convertMilkomedaAssetIdToCardanoFingerprint } from "./address";
import { MAIN_ASSET_ID } from "./proposals";
import { validatorTookAction } from "./common";

import config from "../config/config.json";
import { ConfigType } from "../config/config";
import { ValidatorChangeTxInterface } from "../types/ProposalTypes";
import { RequestChangeValidatorInterface } from "../types/RequestChangeValidator.interface";
import { ChangeValidatorType } from "../types/ChangeValidatorType.enum";
const cfg = config as ConfigType;

export async function getRequests(
  statusFilter: ActionStatus | null | undefined,
  typeFilter: ActionType | null | undefined,
  proposerAddress: string | null | undefined,
  currentPage: number | null | undefined,
  perPage: number | null | undefined,
  txID: string | null | undefined,
  currentNetworkKey: string
) {
  try {
    const request = await axios.get<ApiGetRequestsResponse>(
      `${cfg[currentNetworkKey].indexerEndpointBaseUrl}/vc/requests`,
      {
        params: {
          status: statusFilter,
          type: typeFilter,
          proposer: proposerAddress,
          page: currentPage,
          perPage: perPage,
          id: txID,
        },
      }
    );

    if (request.data !== null && request?.data.data) {
      const mappedRequests = mapRequestDetailsBasedOnTypes(
        request.data.data,
        currentNetworkKey
      ) as RequestInterface[];

      return {
        data: mappedRequests,
        allCount: request.data.allCount,
        maxPage: request.data.maxPage,
        perPage: request.data.perPage,
      } as unknown as ApiGetRequestsResponse;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export const convertBytesToHexString = (bytes: number[]): string => {
  return bytes.map((byte) => byte.toString(16).padStart(2, "0")).join("");
};

export function mapRequestDetailsBasedOnTypes(
  requests: RequestInterface[],
  currentNetworkKey: string
): RequestInterface[] | undefined {
  if (requests.length === 0 || requests[0]?.raw === undefined) return requests;
  switch (requests[0].actionType) {
    case ActionType.L1_TRANSACTION: {
      const raw = requests[0].raw as L1TransactionIndexer;

      if (raw.asset_id === MAIN_ASSET_ID) {
        requests[0].sidechainTokenId = raw.asset_id;
        requests[0].sidechainTokenAmount = raw.amount;
      } else {
        requests[0].token1Name = MAIN_ASSET_ID;
        requests[0].token1Value = raw.wmain_amount;

        requests[0].token2Name = raw.asset_id;
        requests[0].token2Value = raw.amount;
      }
      requests[0].refundedTransactionId = raw.related_tx;
      requests[0].destinationAddress = raw.destination;

      break;
    }

    case ActionType.L2_TRANSACTION: {
      const raw = requests[0].raw as L2TransactionIndexer;
      requests[0].refundedTransactionId = raw.related_tx;
      requests[0].destinationAddress = raw.destination;
      requests[0].sidechainTokenId = raw.asset_id;
      requests[0].sidechainTokenAmount = raw.amount;

      break;
    }

    case ActionType.ADD_TOKEN: {
      const raw = requests[0].raw as AssetUpdate;

      const mainchainAssetId = convertMilkomedaAssetIdToCardanoFingerprint(
        raw.assetId
      );
      requests[0].mainchainAssetId = mainchainAssetId;
      requests[0].sidechainTokenId = raw.assetId;
      requests[0].tokenContract = raw.update.entry.tokenContract;
      requests[0].decimalPrecisionCardano = raw.update.entry.mainChainDecimal;
      requests[0].decimalPrecisionMilkomeda = raw.update.entry.sideChainDecimal;
      requests[0].symbol = raw.update.entry.symbol;
      requests[0].mainchainExplorerLink = `${cfg[currentNetworkKey].mainchainExplorer}/asset/${mainchainAssetId}`;
      requests[0].evmExplorerLink = `${cfg[currentNetworkKey].evmExplorer}/token/${raw.update.entry.tokenContract}`;

      break;
    }

    case ActionType.REMOVE_TOKEN: {
      const raw = requests[0].raw as AssetUpdate;

      const mainchainAssetId = convertMilkomedaAssetIdToCardanoFingerprint(
        raw.assetId
      );
      requests[0].mainchainAssetId = mainchainAssetId;
      requests[0].sidechainTokenId = raw.assetId;

      break;
    }

    case ActionType.CHANGE_VALIDATOR_SET: {
      const raw = requests[0].raw as unknown as ValidatorChangeTxInterface;
      let validatorsChangeArr = [];
      for (let op of raw.ops) {
        let singleChange = {} as RequestChangeValidatorInterface;
        if ("Add" in op) {
          singleChange.type = ChangeValidatorType.ADD;
          singleChange.publicKey = convertBytesToHexString(op.Add[0]);
          singleChange.cardanoSignedMessage = convertBytesToHexString(
            op.Add[2].cardano_signature
          );
          singleChange.cardanoSignedMessage = convertBytesToHexString(
            op.Add[2].cardano_signature
          );

          singleChange.evmAddress1 = op.Add[1];
          singleChange.evmSignedMessage = `
          ${op.Add[2].evm_signature.v.toString(16)}${convertBytesToHexString(
            op.Add[2].evm_signature.r
          )}${convertBytesToHexString(op.Add[2].evm_signature.s)}`;
        }

        if ("Remove" in op) {
          singleChange.type = ChangeValidatorType.REMOVE;
          singleChange.publicKey = convertBytesToHexString(op.Remove[0]);
          singleChange.evmAddress1 = op.Remove[1];
        }

        validatorsChangeArr.push(singleChange);
      }

      requests[0].changeValidatorSet = validatorsChangeArr;
      requests[0].startingBlock = raw.starting_block;

      break;
    }
    default: {
      return requests ?? [];
    }
  }

  return requests ?? [];
}

export async function getMyTasks(
  address: string | null | undefined,
  currentPage: number | null | undefined,
  perPage: number | null | undefined,
  typeFilter: ActionType | null | undefined,
  txID: string | null | undefined,
  currentNetworkKey: string,
  primaryAddress: string
) {
  try {
    let request = await axios.get<ApiGetTasksResponse>(
      `${cfg[currentNetworkKey].indexerEndpointBaseUrl}/vc/tasks/${address}`,
      {
        params: {
          page: currentPage,
          perPage: perPage,
          type: typeFilter,
          id: txID,
        },
      }
    );

    // filter out already approved tasks by the validator that is logged in
    let finalFilteredTasks = [];
    if (request.data?.data) {
      for (let task of request?.data?.data) {
        const wasAlreadyActionTaken = validatorTookAction(
          task.validators ?? [],
          primaryAddress ?? ""
        );
        if (!wasAlreadyActionTaken) finalFilteredTasks.push(task);
      }
    }

    // map from InitialTaskInterface to ApiGetRequestsResponse
    const mappedTasks: RequestInterface[] = finalFilteredTasks.map((task) => ({
      approvedCount: task.approved,
      rejectCount: task.rejected,
      validatorsCount: task.validatorsCount,
      quorumCount: task.quorum,
      actionStatus: task.status as ActionStatus,
      actionType: task.type as ActionType,
      confirmationId: task.confirmationId ?? "",
      confirmationTimestamp: (task.confirmationTimestamp ?? 0) as number,
      timestamp: Number(task.timestamp) || 0,
      proposerAddress: task.proposerAddress ?? "",
      validators: task.validators ?? [],
      id: task.id,
      raw: task.raw,
      symbol: "",
      failedReason: task.failedReason,
    }));

    if (request.data !== null && request?.data.data) {
      const mappedRequests = mapRequestDetailsBasedOnTypes(
        mappedTasks,
        currentNetworkKey
      ) as RequestInterface[];

      return {
        data: mappedRequests,
        allCount: mappedRequests.length,
        maxPage: request.data.maxPage,
        perPage: request.data.perPage,
      } as unknown as ApiGetRequestsResponse;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getValidators(apiBaseUrl: string | null | undefined) {
  try {
    const request = await axios.get<ApiGetValidatorsResponse>(
      `${apiBaseUrl}/vc/validators`
    );

    return request.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}
