import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";

import Layout from "../../components/Layout";
import Button from "../../components/Button";
import Input from "../../components/Input";
import TextDropdown, { DropdownOption } from "../../components/TextDropdown";
import PageHeader from "../../components/PageHeader";

import {
  TokenRegistryInterface,
  TokenRegistryUpdateState,
} from "../../types/ProposalTypes";
import { LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY } from "../../constants";
import { ProposalsManager } from "../../proposalsManager";
import useTokensList from "../../hooks/useTokensList";
import { useLocalStorage } from "../../hooks/useLocalStorage";

import config from "../../config/config.json";
import { ConfigType } from "../../config/config";
import { mapNetworkIdWithName } from "../../utils/common";
const cfg = config as ConfigType;

const RemoveTokenForm = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const walletClient = useWalletClient();
  const client = usePublicClient();

  const [localStoragePrimaryWalletAddress] = useLocalStorage(
    LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY
  );

  const submitTokenRegistryUpdateProposal = async () => {
    const tokenRegistryPayload: TokenRegistryInterface = {
      assetId: milkomedaAssetId,
      update: {
        state: TokenRegistryUpdateState.Removed,
      },
    };

    const { id, message } =
      ProposalsManager.buildTRMessage(tokenRegistryPayload);

    const secondaryAddress = walletClient.data?.account.address.toString();
    if (!secondaryAddress) {
      throw Error(
        `SecondaryAddress set is ${secondaryAddress}. Reconnect your wallet and reset local storage. Then try again)`
      );
    }

    const txCount = await client.getTransactionCount({
      address: secondaryAddress as any,
    });

    const res = await ProposalsManager.postMessage(
      id,
      message,
      primaryAddress,
      secondaryAddress,
      txCount,
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .validatorsChannelContract
    );

    if (res !== null) {
      navigate("/proposal/remove-token/success");
    }

    return;
  };

  const { tokensList } = useTokensList();

  const [milkomedaAssetId, setMilkomedaAssetId] = useState("");
  const [primaryAddress, setPrimaryAddress] = useState(
    localStoragePrimaryWalletAddress ?? ""
  );
  const [selectedToken, setSelectedToken] = useState<DropdownOption>();

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [isConnected, navigate]);

  useEffect(() => {
    if (!selectedToken) {
      return;
    }

    setMilkomedaAssetId(selectedToken.id);
  }, [selectedToken]);

  const submitAllowed = !!primaryAddress && !!milkomedaAssetId;

  return (
    <Layout title="Dashboard">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageHeader
          title="Remove token"
          description="Easily remove tokens from our system with our simple and efficient
            form."
        />
        <div className="container z-10 relative overflow-auto">
          <div className="mx-auto max-w-[600px]">
            <div className="space-y-4 mt-8">
              <Input
                required
                onInputChange={(val) => setPrimaryAddress(val)}
                value={primaryAddress}
                label="Primary wallet address"
                labelAddition={
                  !!localStoragePrimaryWalletAddress
                    ? "(pre-filled address)"
                    : ""
                }
              />
              <TextDropdown
                required
                options={tokensList.map((token) => {
                  return {
                    id: token.assetID,
                    label: token?.symbol ?? "",
                  };
                })}
                selectedOption={selectedToken}
                label="Token to remove"
                onOptionChange={(o) => setSelectedToken(o)}
                placeholder="Select token to remove"
                tallerYSpacing
              />
            </div>
            <div className="mt-6">
              <Button
                onClick={async () => await submitTokenRegistryUpdateProposal()}
                disabled={!submitAllowed}
              >
                Submit proposal
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RemoveTokenForm;
