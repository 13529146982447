import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount } from "wagmi";

import Layout from "../components/Layout";
import Link from "../components/Link";
import RequestStatistics from "../components/Detail/RequestStatistics";
import RequestDetail from "../components/Detail/RequestDetail";
import RequestValidators from "../components/Detail/RequestValidators";
import RequestStatusBadge from "../components/Detail/RequestStatusBadge";

import { mapActionTypeToMessage } from "../utils/requests";
import { useRequestDetail } from "../hooks/useFilteredRequests";

import Spinner from "../components/Spinner";
import PageGradient from "../components/PageGradient";

const RequestDetailPage = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const { id } = useParams();

  const { requestData, isLoading } = useRequestDetail(id);

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [isConnected, navigate]);

  return (
    <Layout title="Task Detail">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageGradient />
        <div className="container z-10 relative">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
            <div className="w-[250px]">
              <Link to="/requests">{"< Back"}</Link>
            </div>
            <h1 className="text-20 text-white font-bold text-center">
              {isLoading || !requestData ? (
                <div className="flex items-center justify-center">
                  <Spinner size={16} />
                </div>
              ) : (
                mapActionTypeToMessage[requestData.actionType]
              )}
            </h1>
            <div className="w-[250px] mx-auto md:mx-0" />
          </div>
          <div className="mt-4 md:mt-10 space-y-6">
            {isLoading || !requestData ? (
              <div className="min-h-[158px] flex flex-col items-center justify-center">
                <Spinner size={16} />
              </div>
            ) : (
              <>
                <RequestStatusBadge
                  status={requestData.actionStatus}
                  timestamp={requestData.timestamp}
                />
                <RequestStatistics
                  approvedCount={requestData.approvedCount}
                  rejectCount={requestData.rejectCount}
                  quorumCount={requestData.quorumCount}
                  validatorsCount={requestData.validatorsCount}
                />
              </>
            )}
          </div>
          <div className="mt-8 flex flex-col-reverse gap-6 lg:gap-4 lg:flex-row">
            <main className="lg:basis-[68%]">
              {isLoading || !requestData ? (
                <Spinner size={16} />
              ) : (
                <RequestDetail {...requestData} />
              )}
            </main>
            <aside className="flex flex-col gap-6 md:gap-4 md:flex-row md:items-start lg:basis-[32%] lg:flex-col">
              <RequestValidators validatorsData={requestData?.validators} />
            </aside>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RequestDetailPage;
