import { PossibleFormDataFields } from "../pages/proposals/ChangeValidatorSet";
import { ActionStatus } from "../types/ActionStatus.enum";
import { ActionType } from "../types/ActionType.enum";
import { ChangeValidatorType } from "../types/ChangeValidatorType.enum";

export const mapActionTypeToMessage: Record<ActionType, string> = {
  [ActionType.ADD_TOKEN]: "Add token",
  [ActionType.CHANGE_VALIDATOR_SET]: "Change Validator Set",
  [ActionType.L1_TRANSACTION]: "L1 Transaction",
  [ActionType.L2_TRANSACTION]: "L2 Transaction",
  [ActionType.REMOVE_TOKEN]: "Remove token",
};

export const mapActionStatusToMessage: Record<ActionStatus, string> = {
  [ActionStatus.AWAITING_APPROVALS]: "Awaiting approvals",
  [ActionStatus.CONFIRMED]: "Confirmed",
  [ActionStatus.FAILED]: "Failed",
  [ActionStatus.PROCESSING_IN_PROGRESS]: "Processing in progress",
  [ActionStatus.REJECTED]: "Rejected",
};

export const mapChangeValidatorTypeToMessage: Record<
  ChangeValidatorType,
  string
> = {
  [ChangeValidatorType.ADD]: "Add validator",
  [ChangeValidatorType.REMOVE]: "Remove validator",
  [ChangeValidatorType.REPLACE]: "Replace validator",
};

export const mapChangeValidatorFieldToLabel: Record<
  keyof PossibleFormDataFields,
  string
> = {
  evmAddress1: "EVM Address 1",
  publicKey: "Public Key",
  validatorToBeRemoved: "Validator to be removed",
  validatorToBeReplaced: "Validator to be replaced",
  evmSignedMessage: "Signed EVM message",
  cardanoSignedmessage: "Signed Cardano message"
};
