import classNames from "classnames";
import { Link as RLink, LinkProps as RLinkProps } from "react-router-dom";

import { getButtonStyles } from "./Button";

interface LinkButtonProps extends RLinkProps {
  outlineVariant?: boolean;
  smallVariant?: boolean;
}

const LinkButton = ({
  outlineVariant = false,
  smallVariant = false,
  className: additionalClasses,
  ...restProps
}: LinkButtonProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <RLink
      {...restProps}
      className={classNames(
        getButtonStyles(outlineVariant, smallVariant),
        additionalClasses
      )}
    />
  );
};

export default LinkButton;
