import { useState } from "react";
import { ReactComponent as CopyIcon } from "../assets/icon-copy.svg";
import Tooltip from "./Tooltip";

interface CopyAddressProps {
  address: string;
  onAddressCopied?: () => void;
}

const CopyAddress = ({ address, onAddressCopied }: CopyAddressProps) => {
  const [hasCopied, setHasCopied] = useState(false);

  async function copyTextToClipboard(address: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(address);
    } else {
      return document.execCommand("copy", true, address);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(address)
      .then(() => {
        onAddressCopied?.();
        setHasCopied(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <button
      onClick={() => handleCopyClick()}
      aria-label="Copy address"
      className="group/copy"
    >
      <Tooltip
        text={hasCopied ? "Copied!" : "Copy address to clipboard"}
        maxWidth="S"
        mobileAlign="right"
        onTooltipMouseLeave={() => {
          setHasCopied(false);
        }}
      >
        <CopyIcon className="text-gray-default group-hover/copy:text-orange-dark fill-current" />
      </Tooltip>
    </button>
  );
};

export default CopyAddress;
