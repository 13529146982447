import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  outlineVariant?: boolean;
  smallVariant?: boolean;
}

const commonButtonStyles = [
  "cursor-pointer",
  "rounded-[8px]",
  "text-center",
  "w-full",
  "block",
  "text-white font-bold",
  "disabled:cursor-not-allowed disabled:bg-dark-light disabled:hover:text-white",
];

const regularSizeStyles = ["text-16", "py-3 px-4 md:px-8"];

const smallSizeStyles = ["text-14", "py-2 px-2"];

const fillButtonStyles = [
  "bg-orange-default hover:bg-orange-hover",
  "border-0",
];

const outlineButtonStyles = [
  "bg-transparent hover:bg-gray-light",
  "text-white hover:text-dark-darker",
  "border border-gray-light",
];

export const getButtonStyles = (
  outlineVariant = false,
  smallVariant = false
) => {
  return [
    commonButtonStyles,
    outlineVariant ? outlineButtonStyles : fillButtonStyles,
    smallVariant ? smallSizeStyles : regularSizeStyles,
  ];
};

const Button = ({
  outlineVariant = false,
  smallVariant = false,
  className: additionalClasses,
  ...restProps
}: ButtonProps) => {
  return (
    <button
      {...restProps}
      className={classNames(
        getButtonStyles(outlineVariant, smallVariant),
        additionalClasses
      )}
    />
  );
};

export default Button;
