// Returns a ellipsis version of a string with the ellipsis being in the middle

import AssetFingerprint from "@emurgo/cip14-js";
import { MAIN_ASSET_ID } from "./proposals";

// eg. `0X97819177AF742660E6D8612F5E7882E538C7D9C9` will become `0x9781917..D9C9`
export function middleEllipsis(address: string, length: number = 15) {
  const splitter = "..";
  const resultingLength = length - splitter.length;
  const tailingLength = 4;

  if (address.length <= length || length <= tailingLength + splitter.length)
    return address;

  return `${address.slice(
    0,
    resultingLength - tailingLength
  )}${splitter}${address.slice(
    address.length - tailingLength,
    address.length
  )}`;
}

export const convertMilkomedaAssetIdToCardanoFingerprint = (
  assetId: string
): string => {
  const ASSET_INITIAL_BYTES = 40;

  if (assetId === MAIN_ASSET_ID) return "";

  // For Cardano we could just return the fingerprint, but some of the tokens doesn't have it on devnet indexer db,
  // so we use CIP14 to create an asset it
  const fingerprint = AssetFingerprint.fromHash(
    Buffer.from(assetId.substring(0, ASSET_INITIAL_BYTES), "hex")
  ).fingerprint();

  return fingerprint;
};
