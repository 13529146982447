import { useCallback, useEffect, useState } from "react";
import { Link, NavLink, NavLinkProps } from "react-router-dom";
import classNames from "classnames";

import WalletConnectionDropdown from "./WalletConnectionDropdown";
import PrimaryWalletAddressModal from "./PrimaryWalletAddressModal";
import CreateProposal from "./CreateProposal";

import { ReactComponent as MainLogo } from "../assets/logo.svg";
import { ReactComponent as Hamburger } from "../assets/hamburger-menu.svg";
import { ReactComponent as HamburgerClose } from "../assets/hamburger-menu-close.svg";
import { useWalletClient } from "wagmi";
import { cfg, mapNetworkIdWithName } from "../utils/common";
import { ProposalsManager } from "../proposalsManager";

interface MenuItem {
  href: string;
  label: string;
}

const menuItems: MenuItem[] = [
  {
    href: "/dashboard",
    label: "Dashboard",
  },
  {
    href: "/tasks",
    label: "My Tasks",
  },
  {
    href: "/requests",
    label: "All Requests",
  },
];

const StyledNavLink = (props: NavLinkProps) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        classNames(
          "hover:cursor-pointer",
          "leading-8",
          isActive
            ? "text-orange-dark underline"
            : "text-gray-light hover:text-white hover:underline"
        )
      }
    />
  );
};

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [allowCreateProposals, setAllowCreateProposals] = useState(false);
  const walletClient = useWalletClient();

  // ensure logged in address is on the secondary addresses list (if it's not redirect to main page)
  // remember to not store the list in localstorage since this may be modified by the user
  // we have layer of security on smart contract and in theory don't need this part, but it's safer to have it
  const isValidatorLoggingIn = useCallback(async () => {
    const isValidator = await ProposalsManager.isValidator(
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .validatorsChannelContract,
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)].bridgeContract,
      walletClient.data?.account.address as any
    );
    return isValidator;
  }, [walletClient.data?.account.address, walletClient.data?.chain.id]);

  useEffect(() => {
    const fetchValidatorStatus = async () => {
      const isValidatorLoggedIn = await isValidatorLoggingIn();
      setAllowCreateProposals(isValidatorLoggedIn);
    };
    fetchValidatorStatus();
  }, [isValidatorLoggingIn]);

  return (
    <nav className="bg-dark-default py-6 px-4 flex items-center justify-between border-b border-b-border-default relative z-30">
      <div className="flex items-center space-x-8">
        <Link to="/dashboard" className="cursor-pointer">
          <MainLogo />
        </Link>
        <ul className="hidden md:flex md:items-center space-x-8">
          {menuItems.map((link, index) => (
            <li key={index}>
              <StyledNavLink to={link.href}>{link.label}</StyledNavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex items-center gap-2">
        <div className="md:hidden">
          <WalletConnectionDropdown openByHover={false} />
        </div>
        <button
          aria-label="Open or close menu"
          className="md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <HamburgerClose /> : <Hamburger />}
        </button>
        <div className="hidden md:flex items-center gap-4">
          {allowCreateProposals && (
            <CreateProposal
              isValidatorsSetAllowed={
                cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
                  .isValidatorSetManagementEnabled
              }
            />
          )}
          <WalletConnectionDropdown />
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden absolute left-0 right-0 top-[73px] w-screen bg-dark-default p-4 border-b border-b-border-default">
          <ul className="flex flex-col md:hidden items-center space-y-3 mb-4">
            {menuItems.map((link, index) => (
              <li key={index}>
                <StyledNavLink to={link.href}>{link.label}</StyledNavLink>
              </li>
            ))}
          </ul>
          <div className="flex flex-col gap-2">
            <CreateProposal
              isValidatorsSetAllowed={
                cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
                  .isValidatorSetManagementEnabled
              }
            />
          </div>
        </div>
      )}
      <PrimaryWalletAddressModal />
    </nav>
  );
};

export default Navbar;
