import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import Layout from "../../components/Layout";
import PageGradient from "../../components/PageGradient";
import LinkButton from "../../components/LinkButton";

import { ProposalSuccessEnum } from "../../types/ProposalSuccess.enum";
import { mapProposalSuccessTypeToTitle } from "../../utils/proposals";

interface ProposalSuccessProps {
  successType: ProposalSuccessEnum;
}

const ProposalSuccess = ({ successType }: ProposalSuccessProps) => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [isConnected, navigate]);

  return (
    <Layout title="Dashboard">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageGradient />
        <div className="container z-10 relative pt-10 lg:pt-14 overflow-auto">
          <div className="max-w-[500px] mx-auto">
            <h1 className="text-20 text-white font-bold text-center">
              {mapProposalSuccessTypeToTitle[successType] ?? "Success"}
            </h1>
            <h2 className="text-12 text-gray-light font-bold text-center mt-2">
              Your proposal has been successfully added, opening up an
              opportunity for the community to vote and have their say on this
              exciting proposal.
            </h2>
            <div className="mt-6 max-w-[300px] mx-auto">
              <LinkButton to={"/tasks"}>See my tasks</LinkButton>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProposalSuccess;
