import { configureChains, createConfig, createStorage } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
// import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";

import { chains } from "../constants";

export const { publicClient, webSocketPublicClient } = configureChains(chains, [
  jsonRpcProvider({
    rpc: (chain) => ({
      http: chain.rpcUrls.public.http[0],
    }),
  }),
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  publicClient,
  storage: createStorage({
    storage: window.localStorage,
    key: "wagmi.milkomeda.validators",
  }),
  connectors: [
    new MetaMaskConnector({
      chains: chains,
    }),
    // TODO: wait til metamask support WalletConnectV2
    // new WalletConnectConnector({
    //   chains: chains,
    //   options: {
    //     projectId: "6c31f409b09ac38ccf01505f5ec42d93",
    //   },
    // }),
    // new WrapperWalletConnectV1Connector({
    //   chains: chains,
    //   options: {
    //     qrcode: true,
    //     rpc: chains.reduce((acc, chain) => {
    //       return {
    //         ...acc,
    //         [chain.id]: chain.rpcUrls.public.http[0],
    //       };
    //     }, {}),
    //   },
    // }),
    // TODO: wait til nufi support window.evmproviders
    // new NuFiWalletConnector({ chains: [currentChain] }),
    // new FlintWalletConnector({ chains: chains }),
  ],
  webSocketPublicClient,
});

export default wagmiConfig;
