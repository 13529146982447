import classNames from "classnames";
import React from "react";
import dayjs from "dayjs";

import CopyAddress from "../CopyAddress";

import { middleEllipsis } from "../../utils/address";

interface ValidatorOverviewTileProps {
  address: string;
  startDate: number;
  averageVotingTime: number;
  showVotingTimeInHours?: boolean;
  requestsCount: number;
}

const getAbbreviatedVotingTime = (time: number) => {
  const seconds = time % 60;
  const minutes = Math.floor((time / 60) % 60);
  const hours = Math.floor((time / 3600) % 24);
  const days = Math.floor(time / 86400);

  if (days > 0) {
    return `${days}D ${hours}H`;
  } else {
    return `${hours}H ${minutes}M ${seconds}S`;
  }
};

const ValidatorOverviewTile = ({
  address,
  startDate,
  averageVotingTime,
  showVotingTimeInHours = false,
  requestsCount,
}: ValidatorOverviewTileProps) => {
  const isVotingTimeNegative = averageVotingTime > 24 * 60 * 60;

  return (
    <div className="flex flex-wrap md:flex-nowrap items-center text-white text-14 font-medium gap-y-[6px] md:gap-y-0 md:gap-x-2">
      <div className="basis-[100%] md:basis-auto md:grow space-y-1">
        <p className="flex items-center gap-1">
          {middleEllipsis(address)} <CopyAddress address={address} />
        </p>
        <p className="text-gray-light text-12">
          Started: {dayjs(startDate).format("DD/MM/YYYY")}
        </p>
      </div>
      <div className="basis-[50%] md:basis-[100px] md:text-center">
        <p
          className={classNames(
            isVotingTimeNegative ? "text-red-default" : "text-green"
          )}
        >
          {showVotingTimeInHours
            ? averageVotingTime
            : getAbbreviatedVotingTime(averageVotingTime)}
        </p>
        <p className="text-gray-light text-12">Avg. voting time</p>
      </div>
      <div className="basis-[50%] md:basis-[80px] md:text-center">
        <p>{requestsCount}</p>
        <p className="text-gray-light text-12">Requests</p>
      </div>
    </div>
  );
};

export default ValidatorOverviewTile;
