import React from "react";

import { ReactComponent as SearchIcon } from "../assets/icon-search.svg";

interface SearchInputProps {
  onSearchChange: (sanitizedValue: string, rawValue: string) => void;
  onSearchSubmit?: () => void;
  placeholder?: string;
  trimWhiteSpaces?: boolean;
}

const SearchInput = ({
  onSearchChange,
  onSearchSubmit,
  placeholder,
  trimWhiteSpaces = true,
}: SearchInputProps) => {
  return (
    <div className="border border-border-light rounded-[12px] p-2 flex items-center gap-2 w-full">
      <input
        onChange={(e) =>
          onSearchChange(
            trimWhiteSpaces ? e.target.value.trim() : e.target.value,
            e.target.value
          )
        }
        placeholder={placeholder}
        className="bg-transparent focus:outline-none text-white text-14 font-semibold basis-full"
      />
      <button
        className="w-[24px] h-[24px] flex items-center justify-center fill-white basis-[24px]"
        aria-label="Submit search"
        onClick={() => onSearchSubmit?.()}
      >
        <SearchIcon />
      </button>
    </div>
  );
};

export default SearchInput;
