import React from "react";

import Panel from "../Panel";
import TaskOverviewTile from "../Tasks/TaskOverviewTile";
import LinkButton from "../LinkButton";

import { useMyTasks } from "../../hooks/useFilteredRequests";

interface HomeMyTasksProps {
  connectedAccount: string;
}

const HomeMyTasks: React.FC<HomeMyTasksProps> = ({ connectedAccount }) => {
  const { requests, allCount, isLoading } = useMyTasks(connectedAccount, 3, 1);

  return (
    <Panel
      title="My Tasks"
      counter={allCount}
      childrenWrapperClassname="space-y-2"
      isLoading={isLoading}
    >
      {requests.map((request) => {
        const { id, ...props } = request;

        return <TaskOverviewTile key={id} {...props} />;
      })}

      <div>
        <LinkButton to="/tasks" className="mt-4">
          Show all
        </LinkButton>
      </div>
    </Panel>
  );
};

export default HomeMyTasks;
