import React from "react";

import { ReactComponent as ColorGradient } from "../assets/bg-orange-blue-gradient.svg";

const PageGradient = () => {
  return (
    <div className="absolute w-full h-[20vw] lg:h-[30vw] max-h-[400px] top-0 left-0 right-0 z-0">
      <ColorGradient className="max-w-[1080px] w-full mx-auto h-auto object-cover" />
    </div>
  );
}

export default PageGradient;