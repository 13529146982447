import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import Layout from "../components/Layout";
import Panel from "../components/Panel";
import RequestRow from "../components/Requests/RequestRow";
import SearchInput from "../components/SearchInput";
import TextDropdown, { TextDropdownProps } from "../components/TextDropdown";
import TabSwitch, { TabOption, TabSwitchProps } from "../components/TabSwitch";
import Pagination from "../components/Pagination";
import NoRequestsMatchCriteria from "../components/NoRequestsMatchCriteria";
import PageHeader from "../components/PageHeader";

import {
  mapActionStatusToMessage,
  mapActionTypeToMessage,
} from "../utils/requests";
import { ActionStatus } from "../types/ActionStatus.enum";
import { ActionType } from "../types/ActionType.enum";
import { useFilteredRequests, useMyTasks } from "../hooks/useFilteredRequests";
import { useDebounce } from "../hooks/useDebounce";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY } from "../constants";

const typeFilterOptions: Pick<TextDropdownProps, "options"> = {
  options: [
    {
      label: "All types",
      id: "all",
    },
    ...Object.values(ActionType).map((type) => ({
      label: mapActionTypeToMessage[type],
      id: type,
    })),
  ],
};

const statusFilterOptions: Pick<TextDropdownProps, "options"> = {
  options: [
    {
      label: "All statuses",
      id: "all",
    },
    ...Object.values(ActionStatus).map((status) => ({
      label: mapActionStatusToMessage[status],
      id: status,
    })),
  ],
};

export const tabOptions: Pick<TabSwitchProps, "options">["options"] = [
  {
    label: "My Tasks",
    id: "my_tasks",
  },
  {
    label: "Created by me",
    id: "created_by_me",
  },
];

const PER_PAGE = 10;

const MyTasks = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();

  const [localStoragePrimaryWalletAddress] = useLocalStorage(
    LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY
  );

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [isConnected, navigate]);

  const [selectedTab, setSelectedTab] = useState<TabOption>(tabOptions[0]);

  const [currentLocalPage, setCurrentLocalPage] = useState(1);

  const [typeFilter, setTypeFilter] = useState(typeFilterOptions.options[0]);
  const [statusFilter, setStatusFilter] = useState(
    statusFilterOptions.options[0]
  );

  const [txIdFilter, setTxIdFilter] = useState<string | undefined>(undefined);
  const debouncedTxIdFilter = useDebounce<string | undefined>(txIdFilter, 500);

  const {
    maxPage: myTasksMaxPage,
    currentPage: myTasksCurrentPage,
    requests: myTasks,
    allCount: myTasksAllCount,
    isLoading: myTasksLoading,
  } = useMyTasks(
    localStoragePrimaryWalletAddress ?? "",
    PER_PAGE,
    currentLocalPage,
    typeFilter.id === "all" ? null : (typeFilter.id as ActionType),
    debouncedTxIdFilter,
    selectedTab.id === "my_tasks"
  );

  const {
    maxPage: createdByMeMaxPage,
    currentPage: createdByMeCurrentPage,
    requests: createdByMe,
    allCount: createdByMeAllCount,
    isLoading: createdByMeLoading,
  } = useFilteredRequests(
    PER_PAGE,
    currentLocalPage,
    statusFilter.id === "all" ? null : (statusFilter.id as ActionStatus),
    typeFilter.id === "all" ? null : (typeFilter.id as ActionType),
    selectedTab.id === "created_by_me"
      ? localStoragePrimaryWalletAddress ?? ""
      : undefined,
    debouncedTxIdFilter,
    selectedTab.id === "created_by_me"
  );

  const handleTabChange = (option: TabOption) => {
    setSelectedTab(option);
    setStatusFilter(statusFilterOptions.options[0]);
    setTypeFilter(typeFilterOptions.options[0]);
    setCurrentLocalPage(1);
  };

  const requestsToDisplay =
    selectedTab.id === "created_by_me" ? createdByMe : myTasks;

  const isLoadingRequests =
    selectedTab.id === "created_by_me" ? createdByMeLoading : myTasksLoading;

  const allCountToDisplay =
    selectedTab.id === "created_by_me" ? createdByMeAllCount : myTasksAllCount;

  useEffect(() => {
    if (
      selectedTab.id === "my_tasks" &&
      statusFilter.id !== ActionStatus.AWAITING_APPROVALS
    ) {
      const awaitingApprovalOption = statusFilterOptions.options.find(
        (option) => option.id === ActionStatus.AWAITING_APPROVALS
      );

      if (!!awaitingApprovalOption) {
        setStatusFilter(awaitingApprovalOption);
      }
    }
  }, [selectedTab, statusFilter]);

  return (
    <Layout title="My Tasks">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageHeader title="My Tasks" />
        <div className="container z-10 relative">
          <div className="max-w-[500px] mx-auto mt-4">
            <TabSwitch
              options={tabOptions}
              onOptionSelected={handleTabChange}
              selectedOption={selectedTab}
            />
          </div>
          <div className="mt-8 flex flex-col-reverse gap-6 lg:gap-4 lg:flex-row">
            <Panel
              withBorder={false}
              title="My Tasks"
              counter={allCountToDisplay}
              childrenWrapperClassname="space-y-3"
              isLoading={isLoadingRequests}
            >
              <div className="flex flex-col gap-3 md:flex-row">
                <div className="basis-full lg:basis-1/2">
                  <SearchInput
                    placeholder="Tx ID..."
                    onSearchChange={(v) => setTxIdFilter(v)}
                  />
                </div>
                <div className="basis-full lg:basis-1/4">
                  <TextDropdown
                    {...typeFilterOptions}
                    selectedOption={typeFilter}
                    optionLabel="Type:"
                    onOptionChange={(o) => setTypeFilter(o)}
                    controlledSelectedOption
                  />
                </div>
                <div className="basis-full lg:basis-1/4">
                  <TextDropdown
                    {...statusFilterOptions}
                    selectedOption={statusFilter}
                    optionLabel="Status:"
                    onOptionChange={(o) => setStatusFilter(o)}
                    controlledSelectedOption
                    disabled={selectedTab.id === "my_tasks"}
                  />
                </div>
              </div>
              {requestsToDisplay.map((task) => {
                return (
                  <RequestRow
                    key={task.id}
                    {...task}
                    detailLink={`/tasks/${task.id}`}
                  />
                );
              })}
              {allCountToDisplay === 0 && !isLoadingRequests && (
                <NoRequestsMatchCriteria />
              )}
              <div className="flex justify-end">
                <Pagination
                  currentPage={
                    selectedTab.id === "created_by_me"
                      ? createdByMeCurrentPage
                      : myTasksCurrentPage
                  }
                  maxPage={
                    selectedTab.id === "created_by_me"
                      ? createdByMeMaxPage
                      : myTasksMaxPage
                  }
                  onPageClick={(page) => setCurrentLocalPage(page)}
                />
              </div>
            </Panel>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MyTasks;
