import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import { useOutsideClick } from "../hooks/useOutsideClick";

import { ReactComponent as ArrowDownIcon } from "../assets/icon-arrow-down.svg";

const routes: { href: string; label: string }[] = [
  {
    href: "/proposal/add-token",
    label: "Add token",
  },
  {
    href: "/proposal/remove-token",
    label: "Remove token",
  },
  {
    href: "/proposal/l1-transaction",
    label: "L1 Transaction",
  },
  {
    href: "/proposal/l2-transaction",
    label: "L2 Transaction",
  },
  {
    href: "/proposal/change-validator-set",
    label: "Change validator set",
  },
];

const CreateProposal = ({
  isValidatorsSetAllowed,
}: {
  isValidatorsSetAllowed?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [links, setLinks] = useState(routes);

  useEffect(() => {
    setLinks(
      !isValidatorsSetAllowed
        ? routes.filter(
            (route) => route.href !== "/proposal/change-validator-set"
          )
        : routes
    );
  }, [isValidatorsSetAllowed]);

  const { pathname } = useLocation();

  const ref = useOutsideClick(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <div className="group relative shrink-0" ref={ref}>
      <div
        className="bg-orange-default text-white text-14 rounded-[8px] py-[7px] md:py-[9px] px-3 md:px-4 cursor-pointer font-bold flex items-center gap-1 justify-center md:justify-start"
        role="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="block md:hidden lg:block">Create new proposal</span>
        <span className="hidden md:block lg:hidden">New proposal</span>
        <div className={classNames(isOpen && "rotate-180")}>
          <ArrowDownIcon />
        </div>
      </div>
      {isOpen && (
        <div className="md:w-full md:absolute md:top-[42px] md:bg-dark-light md:border md:border-border-light md:rounded-[8px]">
          <ul>
            {links.map((link, index) => (
              <li
                key={index}
                className={classNames(
                  "md:hover:bg-option",
                  pathname === link.href && "bg-option",
                  "cursor-pointer",
                  "md:first-of-type:rounded-tl-[8px] md:first-of-type:rounded-tr-[8px]",
                  "md:last-of-type:rounded-bl-[8px] md:last-of-type:rounded-br-[8px]",
                  "text-center md:text-left"
                )}
              >
                <Link
                  to={link.href}
                  className={classNames(
                    "text-white text-14",
                    "block",
                    "px-3 py-2"
                  )}
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CreateProposal;
