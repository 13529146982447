import React from "react";
import classNames from "classnames";

import { ComponentWithChildren } from "../types/ComponentWithChildren.interface";

interface BorderSeparatedListProps extends ComponentWithChildren {
  md2Columns?: boolean;
}

const BorderSeparatedList = ({
  children,
  md2Columns,
}: BorderSeparatedListProps) => {
  return (
    <ul
      className={classNames(
        md2Columns && "columns-1 md:columns-2 lg:columns-1"
      )}
    >
      {React.Children.map(children, (child) => {
        if (!child) return null;

        return (
          <li
            className={classNames(
              "pt-2 pb-3 border-b border-b-border-default",
              md2Columns
                ? "last-of-type:border-b-0 md:last-of-type:border-b lg:last-of-type:border-b-0 inline-block w-full"
                : "last-of-type:border-b-0"
            )}
          >
            {child}
          </li>
        );
      })}
    </ul>
  );
};

export default BorderSeparatedList;
