import { useCallback, useEffect } from "react";
import classNames from "classnames";

import { ComponentWithChildren } from "../types/ComponentWithChildren.interface";

import { ReactComponent as CloseIcon } from "../assets/icon-close.svg";

export interface ModalProps extends ComponentWithChildren {
  isOpen: boolean;
  onClose?: () => void;
  disableClose?: boolean;
  centerBodyVertically?: boolean;
}

const Modal = ({
  children,
  isOpen,
  onClose,
  disableClose = false,
  centerBodyVertically = false,
}: ModalProps) => {
  const handleEscKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        if (!disableClose) {
          onClose?.();
        }
      }
    },
    [onClose, disableClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscKeyPress);
    };
  }, [handleEscKeyPress]);

  return isOpen ? (
    <div
      className={classNames(
        "fixed",
        "w-screen",
        "h-screen",
        "bg-dark-default/80",
        "left-0",
        "top-0",
        "flex",
        centerBodyVertically
          ? "items-center"
          : "items-center lg:items-start lg:pt-[15vw]",
        "justify-center",
        "z-[100]"
      )}
      onClick={() => {
        if (!disableClose) {
          onClose?.();
        }
      }}
    >
      <div
        className="w-full max-w-[450px] mx-auto z-[60] relative flex flex-col items-center justify-center container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-full bg-dark-light p-6 rounded-[16px] border border-border-default relative cursor-default text-white">
          {!disableClose && (
            <button
              className="absolute top-2 right-2 w-[32px] h-[32px] flex items-center justify-center cursor-pointer z-[60]"
              aria-label="Close modal"
              onClick={() => onClose?.()}
            >
              <CloseIcon className="w-[16px] h-[16px]" />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
