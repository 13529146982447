import React from "react";
import classNames from "classnames";

import Panel from "./Panel";

import {
  FormDataFields,
  PossibleFormDataFields,
} from "../pages/proposals/ChangeValidatorSet";
import {
  mapChangeValidatorFieldToLabel,
  mapChangeValidatorTypeToMessage,
} from "../utils/requests";

type ChangeValidatorActionPanelProps = FormDataFields & {
  id: number;
  onEditClick: (id: number) => void;
  onRemoveClick: (id: number) => void;
};

const ChangeValidatorActionPanel = (props: ChangeValidatorActionPanelProps) => {
  const { changeType, onEditClick, onRemoveClick, id, ...restProps } = props;

  return (
    <Panel title={mapChangeValidatorTypeToMessage[changeType]}>
      <div className="space-y-2">
        {Object.entries(restProps).map(([key, value], index) => {
          return (
            <div
              className="flex flex-col md:flex-row gap-1 md:gap-2 py-1"
              key={index}
            >
              <h2 className="flex items-start gap-1 text-gray-light fill-gray-light text-14 md:basis-[200px]">
                {
                  mapChangeValidatorFieldToLabel[
                    key as keyof PossibleFormDataFields
                  ]
                }
              </h2>
              <div className="md:basis-[70%]">
                <p className={classNames("text-14 text-white break-all")}>
                  {value}
                </p>
              </div>
            </div>
          );
        })}
        <div className="flex items-center justify-center gap-2 pt-2">
          <button
            className="text-orange-default text-14 flex-1 p-2 border border-border-default rounded-[8px] hover:border-orange-default "
            onClick={() => onEditClick(id)}
          >
            Edit
          </button>
          <button
            className="text-red-default text-14 flex-1 p-2 border border-border-default rounded-[8px] hover:border-red-default"
            onClick={() => onRemoveClick(id)}
          >
            Remove
          </button>
        </div>
      </div>
    </Panel>
  );
};

export default ChangeValidatorActionPanel;
