import classNames from "classnames";

interface PaginationProps {
  currentPage: number;
  maxPage: number;
  onPageClick: (page: number) => void;
  enableMobileSlimView?: boolean;
  slimViewAllDevices?: boolean;
}

const PaginationSeparator = () => {
  return (
    <li
      className={classNames(
        "text-white text-14 p-1 flex items-center justify-center w-[32px] h-[32px] rounded-[8px] border border-gray-dark bg-dark-default select-none"
      )}
    >
      ...
    </li>
  );
};

const PaginationItem = ({
  page,
  currentPage,
  onPageClick,
}: Pick<PaginationProps, "currentPage" | "onPageClick"> & { page: number }) => {
  return (
    <li
      className={classNames(
        "text-white text-14 p-1 flex items-center justify-center w-[32px] h-[32px] rounded-[8px] border border-gray-dark select-none",
        "hover:cursor-pointer hover:border-orange-default",
        page === currentPage ? "bg-orange-default" : "bg-dark-default"
      )}
      onClick={() => onPageClick(page)}
    >
      {page}
    </li>
  );
};

const Pagination = ({
  currentPage,
  maxPage,
  onPageClick,
  enableMobileSlimView = true,
  slimViewAllDevices = true,
}: PaginationProps) => {
  const slimViewAllowed = enableMobileSlimView && maxPage > 5;

  return (
    <div>
      <ul
        className={classNames(
          "gap-2",
          slimViewAllDevices
            ? "hidden"
            : slimViewAllowed
            ? "hidden md:flex"
            : "flex"
        )}
      >
        {Array.from({ length: maxPage }, (_, i) => i + 1).map((page) => (
          <PaginationItem
            key={page}
            page={page}
            currentPage={currentPage}
            onPageClick={() => onPageClick(page)}
          />
        ))}
      </ul>
      {(slimViewAllowed || slimViewAllDevices) && (
        <ul
          className={classNames(
            "gap-2",
            slimViewAllDevices
              ? "flex"
              : slimViewAllowed
              ? "flex md:hidden"
              : "hidden"
          )}
        >
          {maxPage !== 0 && (
            <PaginationItem
              page={1}
              currentPage={currentPage}
              onPageClick={() => onPageClick(1)}
            />
          )}
          {currentPage > 3 && <PaginationSeparator />}
          {currentPage > 2 && (
            <PaginationItem
              page={currentPage - 1}
              currentPage={currentPage}
              onPageClick={() => onPageClick(currentPage - 1)}
            />
          )}
          {currentPage !== 1 && currentPage !== maxPage && (
            <PaginationItem
              page={currentPage}
              currentPage={currentPage}
              onPageClick={() => onPageClick(currentPage)}
            />
          )}
          {currentPage < maxPage - 1 && (
            <PaginationItem
              page={currentPage + 1}
              currentPage={currentPage}
              onPageClick={() => onPageClick(currentPage + 1)}
            />
          )}
          {currentPage < maxPage - 2 && <PaginationSeparator />}
          {maxPage > 1 && (
            <PaginationItem
              page={maxPage}
              currentPage={currentPage}
              onPageClick={() => onPageClick(maxPage)}
            />
          )}
        </ul>
      )}
    </div>
  );
};

export default Pagination;
