import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useWalletClient, usePublicClient } from "wagmi";

import Layout from "../../components/Layout";
import Button from "../../components/Button";
import Input from "../../components/Input";
import TextDropdown, { DropdownOption } from "../../components/TextDropdown";
import PageHeader from "../../components/PageHeader";

import { L2TxInterface } from "../../types/ProposalTypes";
import { LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY } from "../../constants";
import { ProposalsManager } from "../../proposalsManager";
import useTokensList from "../../hooks/useTokensList";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ethers } from "ethers";
import { mapNetworkIdWithName } from "../../utils/common";

import config from "../../config/config.json";
import { ConfigType } from "../../config/config";
const cfg = config as ConfigType;

const L2TransactionForm = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const walletClient = useWalletClient();
  const client = usePublicClient();

  const submitTokenRegistryUpdateProposal = async () => {
    let mainchainDecimals = tokensList.filter(
      (token) => token.assetID === milkomedaAssetId
    )[0].sidechainDecimals;

    let amountWithDecimals = ethers.utils
      .parseUnits(amount, mainchainDecimals)
      .toString();

    const l2TransactionPayload: L2TxInterface = {
      related_tx: relatedTx,
      sender: "",
      destination: destination,
      asset_id: milkomedaAssetId,
      amount: amountWithDecimals,
    };

    const { id, message } =
      ProposalsManager.buildL2TxMessage(l2TransactionPayload);

    const secondaryAddress = walletClient.data?.account.address.toString();
    if (!secondaryAddress) {
      throw Error(
        `SecondaryAddress set is ${secondaryAddress}. Reconnect your wallet and reset local storage. Then try again)`
      );
    }

    const txCount = await client.getTransactionCount({
      address: secondaryAddress as any,
    });

    const res = await ProposalsManager.postMessage(
      id,
      message,
      primaryAddress,
      secondaryAddress as any,
      txCount,
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .validatorsChannelContract
    );

    if (res !== null) {
      navigate("/proposal/l2-transaction/success");
    }

    return;
  };

  const [localStoragePrimaryWalletAddress] = useLocalStorage(
    LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY
  );

  const [relatedTx, setRelatedTx] = useState("");
  const [destination, setDestination] = useState("");
  const [milkomedaAssetId, setMilkomedaAssetId] = useState("");
  const [amount, setAmount] = useState("");
  const [primaryAddress, setPrimaryAddress] = useState(
    localStoragePrimaryWalletAddress
  );

  const { tokensList } = useTokensList(true);

  const [selectedToken, setSelectedToken] = useState<DropdownOption>();

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [isConnected, navigate]);

  useEffect(() => {
    if (!selectedToken) {
      return;
    }

    setMilkomedaAssetId(selectedToken.id);
  }, [selectedToken]);

  const submitAllowed = !!primaryAddress && !!destination && !!amount;

  return (
    <Layout title="Dashboard">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageHeader
          title="L2 Transaction"
          description="Effortlessly create L2 transactions with our user-friendly form for
            seamless blockchain management."
        />
        <div className="container z-10 relative overflow-auto">
          <div className="mx-auto max-w-[600px]">
            <div className="space-y-4 mt-8">
              <Input
                required
                onInputChange={(val) => setPrimaryAddress(val)}
                value={primaryAddress}
                label="Primary wallet address"
                labelAddition={
                  !!localStoragePrimaryWalletAddress
                    ? "(pre-filled address)"
                    : ""
                }
              />
              <Input
                onInputChange={(val) => setRelatedTx(val)}
                value={relatedTx}
                label="Refunded transaction ID"
                placeholder="If there's one, please provide its hash, so validators have more context"
              />
              <Input
                required
                onInputChange={(val) => setDestination(val)}
                value={destination}
                label="Destination address"
                placeholder="0x000.."
              />
              <TextDropdown
                required
                options={tokensList.map((token) => {
                  return {
                    id: token.assetID,
                    label: token?.symbol ?? "",
                  };
                })}
                selectedOption={selectedToken}
                label="Token"
                onOptionChange={(o) => setSelectedToken(o)}
                placeholder="Select token"
                tallerYSpacing
              />
              {!!milkomedaAssetId && (
                <Input
                  required
                  onInputChange={(val) => setAmount(val)}
                  value={amount}
                  label={`${selectedToken?.label} amount`}
                  enforceNumbers
                />
              )}
            </div>
            <div className="mt-6">
              <Button
                onClick={async () => await submitTokenRegistryUpdateProposal()}
                disabled={!submitAllowed}
              >
                Submit proposal
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default L2TransactionForm;
