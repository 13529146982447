import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import Layout from "../components/Layout";
import HomeAllRequests from "../components/Home/HomeAllRequests";
import HomeMyTasks from "../components/Home/HomeMyTasks";
import HomeValidators from "../components/Home/HomeValidators";
import PageHeader from "../components/PageHeader";

const Dashboard = () => {
  const navigate = useNavigate();
  const { isConnected, address: connectedAccount } = useAccount();

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [connectedAccount, isConnected, navigate]);

  return (
    <Layout title="Dashboard">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageHeader
          title="Dashboard"
          description="Review all Milkomeda Validators requests and tasks"
        />
        <div className="container z-10 relative">
          <div className="mt-8 flex flex-col-reverse gap-6 lg:gap-4 lg:flex-row">
            <main className="lg:basis-[68%]">
              <HomeAllRequests />
            </main>
            <aside className="flex flex-col gap-6 md:gap-4 md:flex-row md:items-start lg:basis-[32%] lg:flex-col">
              <HomeMyTasks connectedAccount={connectedAccount || ""} />
              <HomeValidators />
            </aside>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Dashboard;
