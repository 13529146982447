import React from "react";

interface RequestStatisticsProps {
  approvedCount: number;
  rejectCount: number;
  validatorsCount: number;
  quorumCount: number;
}

const RequestStatistics = ({
  approvedCount,
  rejectCount,
  validatorsCount,
  quorumCount,
}: RequestStatisticsProps) => {
  return (
    <div className="flex flex-col md:flex-row justify-center gap-2 md:gap-4 text-gray-light text-14">
      <p className="flex justify-between items-center">
        Approved (quorum {quorumCount}):&nbsp;
        <span className="text-green">{approvedCount}</span>
      </p>
      <p className="flex justify-between items-center">
        Rejected:&nbsp;<span className="text-red-default">{rejectCount}</span>
      </p>
      <p className="flex justify-between items-center">
        Number of validators:&nbsp;
        <span className="text-white">{validatorsCount}</span>
      </p>
    </div>
  );
};

export default RequestStatistics;
