import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Modal from "./Modal";
import Input from "./Input";
import Button from "./Button";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY } from "../constants";

const PrimaryWalletAddressModal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [localStorageWalletAddress, setLocalStorageWalletAddress] =
    useLocalStorage(LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY);

  const [walletAddress, setWalletAddress] = useState(
    localStorageWalletAddress ?? ""
  );

  const showOnboarding = !!location.state?.onboarding;

  const handleModalClose = () => {
    // replace the history to reset state
    navigate(location.pathname, { replace: true });
    window.location.reload();
  };

  const handleAddressSave = () => {
    setLocalStorageWalletAddress(walletAddress);
    handleModalClose();
  };

  return (
    <Modal
      isOpen={showOnboarding}
      onClose={() => {
        handleModalClose();
      }}
    >
      <div className="space-y-4">
        <h3 className="text-white font-bold text-center">
          Remember primary wallet address
        </h3>
        <p className="text-gray-light text-center text-14">
          Your primary wallet address is needed to submit proposals. You can
          type it every time or we can remember it so you don't have to.
        </p>
        <Input
          placeholder="Primary wallet address"
          // TODO: Handle properly
          value={walletAddress}
          onInputChange={(val) => setWalletAddress(val)}
        />
        <Button
          onClick={() => {
            handleAddressSave();
          }}
        >
          Save address
        </Button>
      </div>
    </Modal>
  );
};

export default PrimaryWalletAddressModal;
