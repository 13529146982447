import React, { useState } from "react";

import RequestRow from "../Requests/RequestRow";
import Panel from "../Panel";
import SearchInput from "../SearchInput";
import TextDropdown, { TextDropdownProps } from "../TextDropdown";
import NoRequestsMatchCriteria from "../NoRequestsMatchCriteria";
import LinkButton from "../LinkButton";

import { ActionStatus } from "../../types/ActionStatus.enum";
import { ActionType } from "../../types/ActionType.enum";
import {
  mapActionStatusToMessage,
  mapActionTypeToMessage,
} from "../../utils/requests";
import { useFilteredRequests } from "../../hooks/useFilteredRequests";
import { useDebounce } from "../../hooks/useDebounce";

const typeFilterOptions: Pick<TextDropdownProps, "options"> = {
  options: [
    {
      label: "All types",
      id: "all",
    },
    ...Object.values(ActionType).map((type) => ({
      label: mapActionTypeToMessage[type],
      id: type,
    })),
  ],
};

const statusFilterOptions: Pick<TextDropdownProps, "options"> = {
  options: [
    {
      label: "All statuses",
      id: "all",
    },
    ...Object.values(ActionStatus).map((status) => ({
      label: mapActionStatusToMessage[status],
      id: status,
    })),
  ],
};

const PER_PAGE = 10;

const HomeAllRequests = () => {
  const [typeFilter, setTypeFilter] = useState(typeFilterOptions.options[0]);
  const [statusFilter, setStatusFilter] = useState(
    statusFilterOptions.options[0]
  );

  const [txIdFilter, setTxIdFilter] = useState<string | undefined>(undefined);
  const debouncedTxIdFilter = useDebounce<string | undefined>(txIdFilter, 500);

  const { requests, allCount, isLoading } = useFilteredRequests(
    PER_PAGE,
    1,
    statusFilter.id === "all" ? null : (statusFilter.id as ActionStatus),
    typeFilter.id === "all" ? null : (typeFilter.id as ActionType),
    undefined,
    debouncedTxIdFilter
  );

  return (
    <Panel
      title="All Requests"
      counter={allCount}
      childrenWrapperClassname="space-y-3"
      isLoading={isLoading}
    >
      <SearchInput
        placeholder="Tx ID..."
        onSearchChange={(v) => setTxIdFilter(v)}
      />
      <div className="flex flex-col gap-3 md:flex-row">
        <TextDropdown
          {...typeFilterOptions}
          selectedOption={typeFilter}
          optionLabel="Type:"
          onOptionChange={(o) => setTypeFilter(o)}
        />
        <TextDropdown
          {...statusFilterOptions}
          selectedOption={statusFilter}
          optionLabel="Status:"
          onOptionChange={(o) => setStatusFilter(o)}
        />
      </div>
      {requests.map((request) => {
        return (
          <RequestRow
            key={request.id}
            {...request}
            detailLink={`/requests/${request.id}`}
            showFullAddressesOnLG={false}
          />
        );
      })}
      {allCount === 0 && !isLoading && <NoRequestsMatchCriteria />}
      <div>
        <LinkButton to="/requests" className="mt-4">
          Show all
        </LinkButton>
      </div>
    </Panel>
  );
};

export default HomeAllRequests;
