import { NetworkName } from "../config/config";
import { RequestValidatorsInterface } from "../types/RequestValidators.interface";

import config from "../config/config.json";
import { ConfigType } from "../config/config";
export const cfg = config as ConfigType;

export const getThousandsSeparatedNumber = (input: string | number): string => {
  const DECIMAL_SEPARATOR = ".";

  const stringInput = typeof input === "string" ? input : input.toString();

  const parts = stringInput.split(DECIMAL_SEPARATOR);

  let result = "";

  if (!!parts[1]) {
    result = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${parts[1]}`;
  } else {
    result = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  return result;
};

export const mapNetworkIdWithName = (id: number | undefined): string => {
  switch (id) {
    case 1000:
      return NetworkName.localnet;
    case 200101:
      return NetworkName.devnet;
    case 2001:
      return NetworkName.mainnet;
    default:
      return NetworkName.localnet;
  }
};

export const validatorTookAction = (
  validators: RequestValidatorsInterface[],
  address: string | null
): boolean => {
  return validators?.some(
    (validator) =>
      (validator.id.toLowerCase() === address?.toLowerCase() &&
        validator.status === "APPROVED") ??
      false
  );
};
