import React from "react";

import {
  mapActionStatusToMessage,
  mapActionTypeToMessage,
} from "../utils/requests";

import { ActionType } from "../types/ActionType.enum";
import { ActionStatus } from "../types/ActionStatus.enum";
import classNames from "classnames";

type IndicativeStatusType = "default" | "pending" | "success" | "fail";

export interface StatusTileProps {
  actionType: ActionType;
  actionStatus: ActionStatus;
  indicativeStatus?: IndicativeStatusType;
  disableColorRecognition?: boolean;
}

const getIndicativeStatusType = (
  status: ActionStatus,
  disableColorRecognition: boolean
): IndicativeStatusType => {
  if (disableColorRecognition) {
    return "default";
  }

  if (
    status === ActionStatus.AWAITING_APPROVALS ||
    status === ActionStatus.PROCESSING_IN_PROGRESS
  ) {
    return "pending";
  }

  if (status === ActionStatus.FAILED || status === ActionStatus.REJECTED) {
    return "fail";
  }

  if (status === ActionStatus.CONFIRMED) {
    return "success";
  }

  return "default";
};

const getTileColorClassnames = (status: IndicativeStatusType) => {
  const baseClasses = "border";

  if (status === "pending") {
    return classNames(
      baseClasses,
      "bg-orange-default/20 border-orange-default"
    );
  }

  if (status === "success") {
    return classNames(baseClasses, "bg-green/20 border-green");
  }

  if (status === "fail") {
    return classNames(baseClasses, "bg-red-default/20 border-red-dark");
  }

  return classNames(baseClasses, "bg-white/10 border-border-light");
};

const getTextColorClassnames = (status: IndicativeStatusType) => {
  if (status === "pending") {
    return "text-orange-default";
  }

  if (status === "success") {
    return "text-green";
  }

  if (status === "fail") {
    return "text-red-default";
  }

  return "text-gray-light";
};

const StatusTile = ({
  actionStatus,
  actionType,
  indicativeStatus,
  disableColorRecognition = false,
}: StatusTileProps) => {
  return (
    <div
      className={classNames(
        getTileColorClassnames(
          indicativeStatus ??
            getIndicativeStatusType(actionStatus, disableColorRecognition)
        ),
        "rounded-[8px] flex flex-col items-center justify-center gap-1 text-white text-14 p-2 font-medium text-center md:min-w-[105px] md:max-w-[105px] md:min-h-[105px] md:max-h-[105px]"
      )}
    >
      <p>{mapActionTypeToMessage[actionType]}</p>
      <p
        className={classNames(
          getTextColorClassnames(
            indicativeStatus ??
              getIndicativeStatusType(actionStatus, disableColorRecognition)
          ),
          "text-12"
        )}
      >
        {mapActionStatusToMessage[actionStatus]}
      </p>
    </div>
  );
};

export default StatusTile;
