import React from "react";

const NoRequestsMatchCriteria = () => {
  return (
    <div className="py-10 text-center text-gray-light">
      <p>No requests match the search criteria.</p>
    </div>
  );
};

export default NoRequestsMatchCriteria;
