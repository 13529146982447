import { AnchorHTMLAttributes } from "react";
import classNames from "classnames";

import { getButtonStyles } from "./Button";

interface ExternalLinkButtonProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  outlineVariant?: boolean;
  smallVariant?: boolean;
}

const ExternalLinkButton = ({
  outlineVariant = false,
  smallVariant = false,
  className: additionalClasses,
  ...restProps
}: ExternalLinkButtonProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      {...restProps}
      className={classNames(
        getButtonStyles(outlineVariant, smallVariant),
        additionalClasses
      )}
    />
  );
};

export default ExternalLinkButton;
