import React, { useRef, useState } from "react";
import classNames from "classnames";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import { useLocation, useNavigate } from "react-router-dom";

import AccountAddressField from "./AccountAddressField";
import ExternalLink from "./ExternalLink";
import LinkButton from "./LinkButton";

import { getAddressExplorerLink } from "../utils/wallet";
import { middleEllipsis } from "../utils/address";
import {
  LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY,
  walletIconMap,
} from "../constants";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useOutsideClick } from "../hooks/useOutsideClick";

import { ReactComponent as DisconnectIcon } from "../assets/icon-disconnect.svg";
import { ReactComponent as WalletIcon } from "../assets/icon-wallet.svg";
import { ReactComponent as ExternalIcon } from "../assets/icon-external-link.svg";
import { ReactComponent as CardanoIcon } from "../assets/icon-cardano-outline.svg";
import { ReactComponent as WarningTriangleIcon } from "../assets/icon-warning-triangle.svg";

interface WalletConnectionDropdownProps {
  openByHover?: boolean;
  closeMobileMenu?: () => void;
}

const WalletConnectionDropdown = ({
  openByHover = true,
  closeMobileMenu,
}: WalletConnectionDropdownProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isConnected, address: connectedAccount, connector } = useAccount();
  const { chain } = useNetwork();
  const { disconnect } = useDisconnect({
    onSuccess() {
      navigate("/");
    },
  });

  const [isOpen, setIsOpen] = useState(false);

  const [localStorageWalletAddress] = useLocalStorage(
    LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY
  );

  const handlePrimaryWalletAddressEdit = () => {
    navigate(location.pathname, { state: { onboarding: true } });
  };

  const modalControlRef = useRef<HTMLDivElement>(null);

  const modalBodyRef = useOutsideClick(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, modalControlRef);

  const Icon = walletIconMap[connector?.id ?? ""];

  return isConnected && connectedAccount ? (
    <div className="group relative">
      {openByHover ? (
        <div className="flex items-center space-x-2 bg-dark-light border border-border-light rounded-[8px] py-[7px] md:py-[9px] px-3 md:px-4 cursor-pointer">
          <WalletIcon />
          <p className="font-medium text-white text-14">
            <span className="block md:hidden lg:block">
              {middleEllipsis(connectedAccount)}
            </span>
            <span className="hidden md:block lg:hidden">
              {middleEllipsis(connectedAccount, 10)}
            </span>
          </p>
        </div>
      ) : (
        <div
          className="flex items-center space-x-2 bg-dark-light border border-border-light rounded-[8px] py-[7px] md:py-[9px] px-3 md:px-4 cursor-pointer"
          role="button"
          onClick={() => {
            setIsOpen(true);
          }}
          ref={modalControlRef}
        >
          <WalletIcon />
        </div>
      )}
      <div
        className={classNames(
          "absolute",
          "top-[42px]",
          "right-0",
          openByHover
            ? "hidden group-hover:block"
            : isOpen
            ? "block"
            : "hidden",
          !openByHover && "w-[80vw] max-w-[300px]",
          "z-40"
        )}
        ref={modalBodyRef}
      >
        <div
          className={classNames(
            "bg-[#1F272E]",
            openByHover && "w-[290px] mt-1",
            "shadow-[0px_2px_16px_rgba(1,10,18,0.8)]",
            "p-4",
            "rounded-[8px]"
          )}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-1">
              {Icon && (
                <div className="w-[24px] h-[24px]">
                  <Icon />
                </div>
              )}
              <p className="text-white font-medium text-14">
                {connector?.name}
              </p>
            </div>
            <ExternalLink
              href={getAddressExplorerLink(connectedAccount, chain?.id)}
              icon={ExternalIcon}
            >
              Explorer
            </ExternalLink>
          </div>
          {!!chain && (
            <div className="mt-6 space-y-2">
              <p className="text-gray-light text-12">Connected network:</p>
              <div className="bg-dark-darker rounded-[12px] py-3 px-4 flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  {chain.unsupported ? (
                    <>
                      <span className="fill-orange-dark w-[16px] h-[16px]">
                        <WarningTriangleIcon />
                      </span>
                      <p className="text-orange-dark text-14 font-medium">
                        Unsupported network
                      </p>
                    </>
                  ) : (
                    <>
                      <span className="fill-gray-light w-[16px] h-[16px]">
                        <CardanoIcon />
                      </span>
                      <p className="text-white text-14 font-medium">
                        {chain.name}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="mt-4 space-y-2">
            <p className="text-gray-light text-12">Connected wallet:</p>
            <AccountAddressField address={connectedAccount} />
          </div>
          {!!localStorageWalletAddress && (
            <div className="mt-4 space-y-2">
              <div className="flex items-center justify-between">
                <p className="text-gray-light text-12">Saved primary wallet:</p>
                <button
                  className="text-orange-default text-12"
                  onClick={() => {
                    handlePrimaryWalletAddressEdit();
                  }}
                >
                  Edit
                </button>
              </div>
              <AccountAddressField address={localStorageWalletAddress} />
            </div>
          )}
          <button
            className="mx-auto flex items-center gap-1 mt-2 px-2 py-1 text-gray-light text-14 font-medium hover:text-white hover:underline cursor-pointer"
            onClick={() => {
              disconnect();
              closeMobileMenu?.();
            }}
          >
            <DisconnectIcon /> Disconnect
          </button>
        </div>
      </div>
    </div>
  ) : (
    <LinkButton smallVariant to="/" className="w-full">
      Connect Wallet
    </LinkButton>
  );
};

export default WalletConnectionDropdown;
