import classNames from "classnames";

export interface TabOption {
  label: string;
  id: string | number;
}

export interface TabSwitchProps {
  options: TabOption[];
  selectedOption: TabOption;
  onOptionSelected: (option: TabOption) => void;
}

const TabSwitch = ({
  options,
  onOptionSelected,
  selectedOption,
}: TabSwitchProps) => {
  return (
    <ul className="sm:flex sm:items-stretch rounded-[8px]">
      {options.map((option, index) => {
        return (
          <li
            key={index}
            onClick={() => {
              onOptionSelected(option);
            }}
            className={classNames(
              "text-14 text-center",
              selectedOption.id === option.id
                ? "text-white"
                : "text-gray-light",
              "py-[6px] px-[24px]",
              "flex-1",
              selectedOption.id === option.id
                ? "bg-border-default"
                : "bg-transparent",
              "first:rounded-tl-[6px] first:rounded-tr-[6px] sm:first:rounded-tr-[0px]  sm:first:rounded-bl-[6px]",
              "sm:last:rounded-tr-[6px] last:rounded-br-[6px] last:rounded-bl-[6px] sm:last:rounded-bl-[0px]",
              "cursor-pointer",
              "border-t border-b border-l border-border-default last:border-r border-r sm:border-r-0 sm:last:border-r"
            )}
          >
            {option.label}
          </li>
        );
      })}
    </ul>
  );
};

export default TabSwitch;
