export interface ConfigType {
  [key: string]: Network;
}

export enum NetworkName {
  localnet = "localnet",
  devnet = "devnet",
  mainnet = "mainnet",
}

export interface Network {
  validatorsChannelContract: string;
  bridgeContract: string;
  indexerEndpointBaseUrl: string;
  mainchainExplorer: string;
  evmExplorer: string;
  rpcUrl: string;
  isValidatorSetManagementEnabled: boolean;
}
