import React, { createContext, PropsWithChildren, useContext } from "react";
import {
  PublicClient,
  usePublicClient,
  useWalletClient,
  WalletClient,
} from "wagmi";

export interface WalletContext {
  provider?: PublicClient;
  signer?: WalletClient;
}

export const walletContext = createContext<WalletContext>({});

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const provider = usePublicClient();
  const { data: signer } = useWalletClient();

  return (
    <walletContext.Provider
      value={{
        provider,
        signer: signer || undefined,
      }}
    >
      {children}
    </walletContext.Provider>
  );
};

export const useAuth = () => useContext(walletContext);
