import React from "react";
import dayjs from "dayjs";
import classNames from "classnames";

import StatusTile, { StatusTileProps } from "../StatusTile";
import CopyAddress from "../CopyAddress";
import Link from "../Link";

import { middleEllipsis } from "../../utils/address";
import { RequestInterface } from "../../types/Request.interface";

interface RequestRowProps extends StatusTileProps, RequestInterface {
  detailLink?: string;
  showFullAddressesOnLG?: boolean;
}

const RequestRow = ({
  id,
  actionStatus,
  actionType,
  indicativeStatus,
  disableColorRecognition,
  proposerAddress,
  timestamp,
  confirmationId,
  confirmationTimestamp,
  approvedCount,
  rejectCount,
  validatorsCount,
  quorumCount,
  detailLink = "#",
  showFullAddressesOnLG = true,
}: RequestRowProps) => {
  return (
    <div className="border border-border-default rounded-[12px] bg-dark-light p-3">
      <div className="flex flex-col md:flex-row md:divide-x md:divide-border-default">
        <div className="flex flex-col md:flex-row md:items-center md:basis-full">
          <StatusTile
            actionStatus={actionStatus}
            actionType={actionType}
            indicativeStatus={indicativeStatus}
            disableColorRecognition={disableColorRecognition}
          />
          <div className="text-14 font-medium text-gray-light flex flex-col gap-2 w-full pt-4 pb-3 md:pt-0 md:pb-0 md:pl-4 md:pr-4 border-b md:border-b-0 border-b-border-default">
            <p className="flex items-start justify-between md:justify-start gap-2 text-12">
              Tx ID:
              <span className="text-white flex items-center gap-1" title={id}>
                {showFullAddressesOnLG && (
                  <span className="hidden lg:inline-block">{id} </span>
                )}
                <span
                  className={classNames(
                    "hidden md:inline-block",
                    showFullAddressesOnLG && "lg:hidden"
                  )}
                >
                  {middleEllipsis(id, 16)}{" "}
                </span>
                <span className="md:hidden">{middleEllipsis(id, 10)} </span>
                <CopyAddress address={id} />
              </span>
            </p>
            <p className="flex items-start justify-between md:justify-start gap-2 text-12">
              Proposed by:
              <span
                className="text-white flex items-center gap-1"
                title={proposerAddress}
              >
                {showFullAddressesOnLG && (
                  <span className="hidden lg:inline-block">
                    {proposerAddress}{" "}
                  </span>
                )}
                <span
                  className={classNames(
                    "hidden md:inline-block",
                    showFullAddressesOnLG && "lg:hidden"
                  )}
                >
                  {middleEllipsis(proposerAddress, 16)}{" "}
                </span>
                <span className="md:hidden">
                  {middleEllipsis(proposerAddress, 10)}{" "}
                </span>
                <CopyAddress address={proposerAddress} />
              </span>
            </p>
            <p className="flex items-start justify-between md:justify-start gap-2 text-12">
              Timestamp:
              <span className="text-white text-right">
                {dayjs(timestamp).format("DD/MM/YYYY HH:mm:ss [GMT]Z")}
              </span>
            </p>
            <p className="flex items-start justify-between md:justify-start gap-2 text-12">
              Conf. event ID:
              <span className="text-white text-right">
                {!!confirmationId ? (
                  <span
                    className="text-white flex items-center gap-1"
                    title={confirmationId}
                  >
                    {showFullAddressesOnLG && (
                      <span className="hidden lg:inline-block">
                        {confirmationId}{" "}
                      </span>
                    )}
                    <span
                      className={classNames(
                        "hidden md:inline-block",
                        showFullAddressesOnLG && "lg:hidden"
                      )}
                    >
                      {middleEllipsis(confirmationId, 16)}{" "}
                    </span>
                    <span className="md:hidden">
                      {middleEllipsis(confirmationId, 10)}{" "}
                    </span>
                    <CopyAddress address={confirmationId} />
                  </span>
                ) : (
                  "Awaiting confirmation"
                )}
              </span>
            </p>
            <p className="flex items-start justify-between md:justify-start gap-2 text-12">
              Conf. Timestamp:
              <span className="text-white text-right md:text-left">
                {!!confirmationTimestamp
                  ? dayjs(confirmationTimestamp).format(
                      "DD/MM/YYYY HH:mm:ss [GMT]Z"
                    )
                  : "Awaiting confirmation"}
              </span>
            </p>
          </div>
        </div>
        <div className="text-14 font-medium text-gray-light flex flex-col justify-center gap-2 w-full pt-2 pb-3 md:pt-0 md:pb-0 md:pl-4 md:pr-4 border-b border-b-border-default md:border-b-0 md:basis-[200px] md:shrink-0">
          <p className="flex items-center justify-between gap-2 text-12">
            Approved (quorum {quorumCount}):
            <span className="text-green text-14">{approvedCount}</span>
          </p>
          <p className="flex items-center justify-between gap-2 text-12">
            Rejected:{" "}
            <span className="text-red-default text-14">{rejectCount}</span>
          </p>
          <p className="flex items-center justify-between gap-2 text-12">
            Number of Validators:
            <span className="text-white text-14">{validatorsCount}</span>
          </p>
        </div>
        <div className="pt-3 pb-1 md:pt-0 md:pb-0 md:pl-2 flex justify-center md:justify-end items-center md:basis-[75px] md:shrink-0">
          <Link to={detailLink} className="text-14">
            Details {">"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RequestRow;
