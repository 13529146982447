import { useCallback, useEffect } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useAccount, useWalletClient } from "wagmi";
import { ComponentWithChildren } from "../types/ComponentWithChildren.interface";
import { ProposalsManager } from "../proposalsManager";
import { cfg, mapNetworkIdWithName } from "../utils/common";

const ProtectedRoute = ({ children }: ComponentWithChildren) => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const { pathname } = useLocation();

  const walletClient = useWalletClient();

  // ensure logged in address is on the secondary addresses list (if it's not redirect to main page)
  // remember to not store the list in localstorage since this may be modified by the user
  // we have layer of security on smart contract and in theory don't need this part, but it's safer to have it
  const isValidatorLoggingIn = useCallback(async () => {
    const isValidator = await ProposalsManager.isValidator(
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .validatorsChannelContract,
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)].bridgeContract,
      walletClient.data?.account.address as any
    );
    return isValidator;
  }, [walletClient.data?.account.address, walletClient.data?.chain.id]);

  useEffect(() => {
    isValidatorLoggingIn().then((isValidator) => {
      if (!isValidator) return <Navigate to="/" />;
    });
  }, [isValidatorLoggingIn]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    isValidatorLoggingIn();
    if (!isConnected) {
      navigate("/");
    }
  }, [
    isConnected,
    navigate,
    isValidatorLoggingIn,
    walletClient.data?.chain.id,
  ]);

  if (!isConnected) return <Navigate to="/" />;

  return children;
};

export default ProtectedRoute;
