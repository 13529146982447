import { RefObject, useEffect, useRef } from "react";

export const useOutsideClick = (
  callback: () => void,
  ignoredRef?: RefObject<HTMLElement>
) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!!ignoredRef && !!ignoredRef.current) {
        if (
          ref.current &&
          !ref.current.contains(event.target as Node) &&
          !ignoredRef.current.contains(event.target as Node)
        ) {
          callback();
        }
      } else {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback, ignoredRef]);

  return ref;
};
