import React from "react";
import { ComponentWithChildren } from "../../types/ComponentWithChildren.interface";
import BorderSeparatedList from "../BorderSeparatedList";

interface RequestDetailSectionProps extends ComponentWithChildren {
  title?: string;
}

const RequestDetailSection = ({
  children,
  title,
}: RequestDetailSectionProps) => {
  return (
    <div className="border border-dark-light rounded-[12px] p-4 bg-dark-light">
      {!!title ? (
        <div>
          <h2 className="text-white text-16 font-bold">{title}</h2>
          <BorderSeparatedList>{children}</BorderSeparatedList>
        </div>
      ) : (
        <BorderSeparatedList>{children}</BorderSeparatedList>
      )}
    </div>
  );
};

export default RequestDetailSection;
