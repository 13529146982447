import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";

import Layout from "../../components/Layout";
import Button from "../../components/Button";
import Input from "../../components/Input";
import ChangeValidatorActionPanel from "../../components/ChangeValidatorActionPanel";

import { LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY } from "../../constants";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ChangeValidatorType } from "../../types/ChangeValidatorType.enum";

import { ReactComponent as ColorGradient } from "../../assets/bg-orange-blue-gradient.svg";

import { ethers } from "ethers";
import { ValidatorChangeTxInterface } from "../../types/ProposalTypes";
import { ProposalsManager } from "../../proposalsManager";
import config from "../../config/config.json";
import { ConfigType } from "../../config/config";
import { mapNetworkIdWithName } from "../../utils/common";
const cfg = config as ConfigType;

interface AddValidatorFields {
  changeType: ChangeValidatorType.ADD;
  evmAddress1: string;
  evmSignedMessage: string;
  publicKey: string;
  cardanoSignedmessage: string;
}

interface ReplaceValidatorFields {
  changeType: ChangeValidatorType.REPLACE;
  evmAddress1: string;
  publicKey: string;
  validatorToBeReplaced: string;
}

interface RemoveValidatorFields {
  changeType: ChangeValidatorType.REMOVE;
  validatorToBeRemoved: string;
  publicKey: string;
}

export type FormDataFields = { id: number } & (
  | AddValidatorFields
  | RemoveValidatorFields
  | ReplaceValidatorFields
);

export type PossibleFormDataFields = Omit<AddValidatorFields, "changeType"> &
  Omit<RemoveValidatorFields, "changeType"> &
  Omit<ReplaceValidatorFields, "changeType">;

const PartialFormActions = ({
  onCancelClick,
  onSaveClick,
  isSaveEnabled = true,
}: {
  onCancelClick: () => void;
  onSaveClick: () => void;
  isSaveEnabled?: boolean;
}) => {
  return (
    <div className="flex items-center gap-6">
      <Button
        outlineVariant
        smallVariant
        onClick={() => {
          onCancelClick();
        }}
      >
        Cancel
      </Button>
      <Button
        smallVariant
        onClick={() => {
          onSaveClick();
        }}
        disabled={!isSaveEnabled}
      >
        Save
      </Button>
    </div>
  );
};

function hexToBytes(hex: string) {
  let bytes = [];
  for (let i = 0; i < hex.length; i += 2) {
    bytes.push(parseInt(hex.substr(i, 2), 16));
  }
  return bytes;
}

function stringToEthSignature(hexString: string): {
  v: number;
  r: number[];
  s: number[];
} {
  if (hexString.length !== 130) {
    throw new Error("Invalid input string. Expected a string of length 130.");
  }

  const v = parseInt(hexString.slice(0, 2), 16);
  const r = hexString.slice(2, 66);
  const s = hexString.slice(66);

  return {
    v: v,
    r: Array.from({ length: 32 }, (_, i) =>
      parseInt(r.slice(i * 2, i * 2 + 2), 16)
    ),
    s: Array.from({ length: 32 }, (_, i) =>
      parseInt(s.slice(i * 2, i * 2 + 2), 16)
    ),
  };
}

const ChangeValidatorSet = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const walletClient = useWalletClient();
  const client = usePublicClient();

  const submitValidatorsSetUpdateProposal = async () => {
    console.log(formData);

    let validatorsChange: ValidatorChangeTxInterface = {
      ops: [],
      starting_block: executeValidatorsChangeBlockNumber
        ? parseInt(executeValidatorsChangeBlockNumber)
        : 0,
    };

    formData.forEach((item) => {
      if (item.changeType === ChangeValidatorType.ADD) {
        let cardanoSignedMessage = hexToBytes(item.cardanoSignedmessage);
        let cardanoPublicKey = hexToBytes(item.publicKey);
        let evmSignedMessage = stringToEthSignature(item.evmSignedMessage);
        validatorsChange.ops.push({
          Add: [
            cardanoPublicKey,
            item.evmAddress1,
            {
              evm_signature: evmSignedMessage,
              cardano_signature: cardanoSignedMessage,
            },
          ],
        });
      }

      if (item.changeType === ChangeValidatorType.REMOVE) {
        let cardanoPublicKey = hexToBytes(item.publicKey);
        validatorsChange.ops.push({
          Remove: [cardanoPublicKey, item.validatorToBeRemoved],
        });
      }

      // we ignore replace here
    });

    const { id, message } =
      ProposalsManager.buildValidatorsChangeMessage(validatorsChange);

    const secondaryAddress = walletClient.data?.account.address.toString();
    if (!secondaryAddress) {
      throw Error(
        `SecondaryAddress set is ${secondaryAddress}. Reconnect your wallet and reset local storage. Then try again)`
      );
    }

    const txCount = await client.getTransactionCount({
      address: secondaryAddress as any,
    });

    const res = await ProposalsManager.postMessage(
      id,
      message,
      primaryAddress,
      secondaryAddress as any,
      txCount,
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .validatorsChannelContract
    );

    if (res !== null) {
      navigate("/proposal/change-validator-set/success");
    }

    return;
  };

  const [latestBlock, setLatestBlock] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (walletClient && client) {
        console.log(client.chain.rpcUrls.default.http[0]);
        const provider = new ethers.providers.JsonRpcProvider(
          client.chain.rpcUrls.default.http[0]
        );
        const blockNumber = await provider.getBlockNumber();
        setLatestBlock(blockNumber);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [walletClient, client]);

  const [localStoragePrimaryWalletAddress] = useLocalStorage(
    LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY
  );

  const [addingState, setAddingState] = useState<ChangeValidatorType>();
  const [formData, setFormData] = useState<FormDataFields[]>([]);
  const [formIndex, setFormIndex] = useState(0);
  const [currentlyEditingItem, setCurrentlyEditingItem] =
    useState<FormDataFields | null>(null);

  const [primaryAddress, setPrimaryAddress] = useState(
    localStoragePrimaryWalletAddress
  );

  const [evmAddress1, setEvmAddress1] = useState("");

  //
  // eslint-disable-next-line
  const [evmAddress2, setEvmAddress2] = useState("");
  const [evmSignedMessage, setEvmSignedMessage] = useState("");
  const [cardanoSignedMessage, setCardanoSignedMessage] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [validatorToBeReplaced, setValidatorToBeReplaced] = useState("");
  const [validatorToBeRemoved, setValidatorToBeRemoved] = useState("");

  const [
    executeValidatorsChangeBlockNumber,
    setExecuteValidatorsChangeBlockNumber,
  ] = useState("");

  const resetFormData = () => {
    setEvmAddress1("");
    setEvmAddress2("");
    setPublicKey("");
    setEvmSignedMessage("");
    setCardanoSignedMessage("");
    setValidatorToBeReplaced("");
    setValidatorToBeRemoved("");
    setCurrentlyEditingItem(null);
  };

  const handleAddValidatorClick = () => {
    setAddingState(ChangeValidatorType.ADD);
    resetFormData();
  };

  const handleRemoveValidatorClick = () => {
    setAddingState(ChangeValidatorType.REMOVE);
    resetFormData();
  };

  // eslint-disable-next-line
  const handleReplaceValidatorClick = () => {
    setAddingState(ChangeValidatorType.REPLACE);
    resetFormData();
  };

  const handlePartialFormCancelClick = () => {
    if (!!currentlyEditingItem) {
      setFormData([...formData, currentlyEditingItem]);
    }

    setAddingState(undefined);
    resetFormData();
  };

  const handlePartialFormSaveClick = () => {
    if (addingState === ChangeValidatorType.ADD) {
      setFormData([
        ...formData,
        {
          id: formIndex,
          changeType: ChangeValidatorType.ADD,
          evmAddress1: evmAddress1,
          publicKey: publicKey,
          evmSignedMessage: evmSignedMessage,
          cardanoSignedmessage: cardanoSignedMessage,
        },
      ]);
    } else if (addingState === ChangeValidatorType.REMOVE) {
      setFormData([
        ...formData,
        {
          id: formIndex,
          changeType: ChangeValidatorType.REMOVE,
          validatorToBeRemoved: validatorToBeRemoved,
          publicKey: publicKey,
        },
      ]);
    } else if (addingState === ChangeValidatorType.REPLACE) {
      setFormData([
        ...formData,
        {
          id: formIndex,
          changeType: ChangeValidatorType.REPLACE,
          evmAddress1: evmAddress1,
          publicKey: publicKey,
          validatorToBeReplaced: validatorToBeReplaced,
        },
      ]);
    }
    setAddingState(undefined);
    resetFormData();
    setFormIndex(formIndex + 1);
  };

  const handleValidatorItemRemoval = (id: number) => {
    setFormData([...formData.filter((item) => item.id !== id)]);
  };

  const handleValidatorItemEdit = (id: number) => {
    const validatorItem = formData.find((item) => item.id === id);

    if (!validatorItem) {
      return;
    }

    setCurrentlyEditingItem(validatorItem);
    setAddingState(validatorItem.changeType);

    if (validatorItem.changeType === ChangeValidatorType.ADD) {
      setEvmAddress1(validatorItem.evmAddress1);
      setPublicKey(validatorItem.publicKey);
    } else if (validatorItem.changeType === ChangeValidatorType.REMOVE) {
      setValidatorToBeRemoved(validatorItem.validatorToBeRemoved);
    } else if (validatorItem.changeType === ChangeValidatorType.REPLACE) {
      setEvmAddress1(validatorItem.evmAddress1);
      setPublicKey(validatorItem.publicKey);
      setValidatorToBeReplaced(validatorItem.validatorToBeReplaced);
    }

    // remove the item from the list because we'll either save it unmodified in `onCancelClick` or we'll save it with edited data in `onSaveClick`
    handleValidatorItemRemoval(id);
  };

  const submitAllowed = !!formData.length && !!primaryAddress;

  useEffect(() => {
    if (
      !isConnected ||
      !cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .isValidatorSetManagementEnabled
    ) {
      navigate("/");
    }
  }, [isConnected, navigate, walletClient?.data?.chain.id]);

  return (
    <Layout title="Dashboard">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <div className="absolute w-full h-[20vw] lg:h-[30vw] max-h-[400px] top-0 left-0 right-0 z-0">
          <ColorGradient className="max-w-[1080px] w-full mx-auto h-auto object-cover" />
        </div>
        <div className="container z-10 relative overflow-auto">
          <div className="max-w-[500px] mx-auto">
            <h1 className="text-20 text-white font-bold text-center">
              Change validator set
            </h1>
            <h2 className="text-12 text-gray-light font-bold text-center mt-2">
              Effortlessly manage your validator set with our versatile form.
              Choose from options like adding, removing, or replacing validators
              to easily modify your set and maintain optimal network
              performance.
            </h2>
          </div>
          <div className="mx-auto max-w-[600px]">
            {!addingState && (
              <div className="flex items-center gap-4 mt-6">
                <Button
                  outlineVariant
                  smallVariant
                  onClick={() => {
                    handleAddValidatorClick();
                  }}
                >
                  Add validator
                </Button>
                <Button
                  outlineVariant
                  smallVariant
                  onClick={() => {
                    handleRemoveValidatorClick();
                  }}
                >
                  Remove validator
                </Button>
                {/* <Button
                  outlineVariant
                  smallVariant
                  onClick={() => {
                    handleReplaceValidatorClick();
                  }}
                >
                  Replace validator
                </Button> */}
              </div>
            )}
            {addingState === ChangeValidatorType.ADD && (
              <div className="space-y-4 mt-8">
                <Input
                  required
                  onInputChange={(val) => setEvmAddress1(val)}
                  value={evmAddress1}
                  label="EVM Address 1"
                  labelAddition="(bridge address)"
                />
                <Input
                  required
                  onInputChange={(val) => setEvmSignedMessage(val)}
                  value={evmSignedMessage}
                  label="Proof of evm key (signed message with bridge evm wallet)"
                  labelAddition="(signed message with evm key)"
                />
                {/* <Input
                  required
                  onInputChange={(val) => setEvmAddress2(val)}
                  value={evmAddress2}
                  label="EVM Address 2"
                  labelAddition="(secondary address)"
                /> */}
                <Input
                  required
                  onInputChange={(val) => setPublicKey(val)}
                  value={publicKey}
                  label="Mainchain public key"
                  labelAddition="(cardano)"
                />
                <Input
                  required
                  onInputChange={(val) => setCardanoSignedMessage(val)}
                  value={cardanoSignedMessage}
                  label="Proof of cardano key (signed message with bridge Cardano wallet)"
                  labelAddition="(signed message with cardano key)"
                />
                <PartialFormActions
                  onCancelClick={() => {
                    handlePartialFormCancelClick();
                  }}
                  onSaveClick={() => {
                    handlePartialFormSaveClick();
                  }}
                  isSaveEnabled={!!publicKey && !!evmAddress1}
                />
              </div>
            )}
            {addingState === ChangeValidatorType.REPLACE && (
              <div className="space-y-4 mt-8">
                <Input
                  required
                  onInputChange={(val) => setEvmAddress1(val)}
                  value={evmAddress1}
                  label="EVM Address 1"
                  labelAddition="(bridge address)"
                />
                {/* <Input
                  required
                  onInputChange={(val) => setEvmAddress2(val)}
                  value={evmAddress2}
                  label="EVM Address 2"
                  labelAddition="(secondary address)"
                /> */}
                <Input
                  required
                  onInputChange={(val) => setPublicKey(val)}
                  value={publicKey}
                  label="Mainchain public key"
                  labelAddition="(cardano)"
                />
                <Input
                  required
                  onInputChange={(val) => setValidatorToBeReplaced(val)}
                  value={validatorToBeReplaced}
                  label="Validator to be replaced"
                />
                <PartialFormActions
                  onCancelClick={() => {
                    handlePartialFormCancelClick();
                  }}
                  onSaveClick={() => {
                    handlePartialFormSaveClick();
                  }}
                  isSaveEnabled={
                    !!publicKey && !!evmAddress1
                    // !!evmAddress2 &&
                    // !!validatorToBeReplaced
                  }
                />
              </div>
            )}
            {addingState === ChangeValidatorType.REMOVE && (
              <div className="space-y-4 mt-8">
                <Input
                  required
                  onInputChange={(val) => setValidatorToBeRemoved(val)}
                  value={validatorToBeRemoved}
                  label="Validator to be removed"
                />
                <Input
                  required
                  onInputChange={(val) => setPublicKey(val)}
                  value={publicKey}
                  label="Mainchain public key"
                  labelAddition="(cardano)"
                />

                <PartialFormActions
                  onCancelClick={() => {
                    handlePartialFormCancelClick();
                  }}
                  onSaveClick={() => {
                    handlePartialFormSaveClick();
                  }}
                  isSaveEnabled={!!validatorToBeRemoved}
                />
              </div>
            )}
            {!addingState && (
              <>
                <div className="mt-6">
                  <Input
                    required
                    onInputChange={(val) => setPrimaryAddress(val)}
                    value={primaryAddress}
                    label="Primary wallet address"
                    labelAddition={
                      !!localStoragePrimaryWalletAddress
                        ? "(pre-filled address)"
                        : ""
                    }
                  />
                </div>

                <div className="mt-6">
                  <Input
                    required
                    enforceNumbers
                    enforceWholeNumbers
                    onInputChange={(val) => {
                      setExecuteValidatorsChangeBlockNumber(val);
                    }}
                    value={executeValidatorsChangeBlockNumber}
                    label={`Apply changes starting EVM block (latest block: ${
                      latestBlock === 0
                        ? "fetching latest block..."
                        : latestBlock
                    })`}
                  />
                </div>

                <div className="mt-6">
                  {formData.length === 0 ? (
                    <div className="border border-border-default rounded-[12px] p-4 flex items-center justify-center text-gray-light text-14">
                      No actions added yet. Start by selecting an action above.
                    </div>
                  ) : (
                    <div className="space-y-2">
                      {formData
                        .sort((a, b) => a.id - b.id)
                        .map((action, index) => {
                          return (
                            <ChangeValidatorActionPanel
                              key={index}
                              {...action}
                              onEditClick={(id) => handleValidatorItemEdit(id)}
                              onRemoveClick={(id) =>
                                handleValidatorItemRemoval(id)
                              }
                            />
                          );
                        })}
                    </div>
                  )}
                </div>
                <div className="mt-6">
                  <Button
                    onClick={async () => {
                      await submitValidatorsSetUpdateProposal();
                    }}
                    disabled={!submitAllowed}
                  >
                    Submit proposal
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ChangeValidatorSet;
