import React, { useEffect, useState } from "react";

import Panel from "../Panel";
import BorderSeparatedList from "../BorderSeparatedList";
import ValidatorOverviewTile from "../Validators/ValidatorOverviewTile";

import { ValidatorInterface } from "../../types/Validator.interface";
import { getValidators } from "../../utils/api";
import { mapNetworkIdWithName } from "../../utils/common";
import { useWalletClient } from "wagmi";

import config from "../../config/config.json";
import { ConfigType } from "../../config/config";
const cfg = config as ConfigType;

const HomeValidators = () => {
  const [validators, setValidators] = useState<ValidatorInterface[]>([]);
  const walletClient = useWalletClient();

  useEffect(() => {
    (async () => {
      const validators = await getValidators(
        cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
          .indexerEndpointBaseUrl
      );
      if (!!validators && !!validators?.length) {
        setValidators(validators);
      }
    })();
  }, [walletClient?.data?.chain.id]);

  if (!validators.length) {
    return null;
  }

  return (
    <Panel title="Validators" counter={validators.length}>
      <BorderSeparatedList>
        {validators.map((validator) => (
          <ValidatorOverviewTile
            key={validator.address}
            address={validator.address}
            requestsCount={validator.requestsCount}
            averageVotingTime={validator.averageVotingTime}
            startDate={validator.startDateTimestamp}
          />
        ))}
      </BorderSeparatedList>
    </Panel>
  );
};

export default HomeValidators;
