import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";

import CopyAddress from "../CopyAddress";

import { middleEllipsis } from "../../utils/address";
import { ValidatorStatus } from "../../types/ValidatorStatus.enum";
import { RequestValidatorsInterface } from "../../types/RequestValidators.interface";

interface ValidatorVoteTileProps extends RequestValidatorsInterface {}

const getStatusTextColorClassname = (status: ValidatorStatus) => {
  if (status === ValidatorStatus.APPROVED) {
    return "text-green";
  }

  if (status === ValidatorStatus.REJECTED) {
    return "text-red-default";
  }

  return "text-orange-default";
};

const PingButton = () => {
  return (
    <button className="rounded-[12px] px-2 py-[2px] border border-gray-default text-12 text-white cursor-pointer hover:border-orange-default hover:text-orange-default">
      Ping<span className="hidden sm:inline-block">&nbsp;on Discord</span>
    </button>
  );
};

const ValidatorVoteTile = ({ id, date, status }: ValidatorVoteTileProps) => {
  return (
    <div className="flex flex-nowrap items-center text-white text-14 font-medium gap-x-2">
      <div className="basis-auto grow space-y-1">
        <p className="flex items-center gap-1">
          {middleEllipsis(id)} <CopyAddress address={id} />
        </p>
        <div className="min-h-[22px]">
          {date !== null ? (
            <p className="text-gray-light text-12">
              Date: {dayjs(date).format("DD/MM/YYYY HH:mm:ss")}
            </p>
          ) : (
            <div className="flex items-center gap-2">
              <p className="text-gray-light text-12">Date: Awaiting</p>
              <PingButton />
            </div>
          )}
        </div>
      </div>
      <div className="basis-[70px] text-right">
        <p
          className={classNames(
            getStatusTextColorClassname(status),
            "capitalize"
          )}
        >
          {status}
        </p>
        <p className="text-gray-light text-12">Vote</p>
      </div>
    </div>
  );
};

export default ValidatorVoteTile;
