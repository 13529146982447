import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

import Layout from "../components/Layout";
import ConnectWallet from "../components/ConnectWallet";
import PageGradient from "../components/PageGradient";

const Home = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();

  useEffect(() => {
    if (isConnected) {
      navigate("/dashboard");
    }
  }, [isConnected, navigate]);

  return (
    <Layout title="Home">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageGradient />
        <div className="container z-10 relative">
          {!isConnected && (
            <div className="mt-10">
              <ConnectWallet />
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default Home;
