import { chains } from "../constants";

export function getAddressExplorerLink(
  address: string,
  chainId?: number
): string {
  return `${
    chains.find((chain) => chain.id === chainId)?.blockExplorers?.default?.url
  }/address/${address}`;
}

export function getTXExplorerLink(address: string, chainId?: number): string {
  return `${
    chains.find((chain) => chain.id === chainId)?.blockExplorers?.default?.url
  }/tx/${address}`;
}

export const addTokenToMetamask = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number
): Promise<boolean> => {
  try {
    const tokenAdded = await window.ethereum?.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
        },
      },
    });

    return Boolean(tokenAdded);
  } catch (error) {
    console.error(error);
    return false;
  }
};
