import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";

import { ActionStatus } from "../../types/ActionStatus.enum";
import { mapActionStatusToMessage } from "../../utils/requests";

interface RequestStatusBadgeProps {
  status: ActionStatus;
  timestamp: number;
}

const getBgColorClassnames = (status: ActionStatus) => {
  if (
    status === ActionStatus.AWAITING_APPROVALS ||
    status === ActionStatus.PROCESSING_IN_PROGRESS
  ) {
    return "bg-orange-default border-orange-default";
  }

  if (status === ActionStatus.FAILED || status === ActionStatus.REJECTED) {
    return "bg-red-default border-red-dark";
  }

  return "bg-green border-green";
};

const RequestStatusBadge = ({ status, timestamp }: RequestStatusBadgeProps) => {
  return (
    <div
      className={classNames(
        "border",
        getBgColorClassnames(status),
        "max-w-[260px] mx-auto",
        "rounded-[12px]",
        "px-4 pt-1 pb-[6px]",
        "flex flex-col gap-[2px]"
      )}
    >
      <p className="text-white text-center font-bold">
        {mapActionStatusToMessage[status]}
      </p>
      <p className="text-14 text-white text-center">
        {dayjs(timestamp).format("DD/MM/YYYY HH:mm:ss [GMT]Z")}
      </p>
    </div>
  );
};

export default RequestStatusBadge;
