import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";

import Layout from "../../components/Layout";

import Button from "../../components/Button";
import Input from "../../components/Input";
import TextDropdown, { DropdownOption } from "../../components/TextDropdown";
import PageHeader from "../../components/PageHeader";

import { LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY } from "../../constants";
import { ProposalsManager } from "../../proposalsManager";
import { L1TxInterface } from "../../types/ProposalTypes";
import useTokensList from "../../hooks/useTokensList";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MAIN_ASSET_ID } from "../../utils/proposals";
import { ethers } from "ethers";

import config from "../../config/config.json";
import { ConfigType } from "../../config/config";
import { mapNetworkIdWithName } from "../../utils/common";
const cfg = config as ConfigType;

const L1Transactionform = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const walletClient = useWalletClient();
  const client = usePublicClient();

  const submitL1TransactionProposal = async () => {
    let wmainAmountInWei = ethers.utils.parseUnits(wmainAmount, 6).toString();

    let l1Transaction: L1TxInterface = {
      related_tx: relatedTx,
      destination: destination,
      wmain_amount: Number(wmainAmountInWei),
      sender: "",
      asset_id: "",
      amount: 0,
    };
    if (milkomedaAssetId === "" || !milkomedaAssetId) {
      l1Transaction.asset_id = MAIN_ASSET_ID;
      l1Transaction.amount = Number(wmainAmountInWei);
    } else {
      l1Transaction.asset_id = milkomedaAssetId;
      l1Transaction.amount = Number(amount);
    }

    const { id, message } = ProposalsManager.buildL1TxMessage(l1Transaction);

    const secondaryAddress = walletClient.data?.account.address.toString();
    if (!secondaryAddress) {
      throw Error(
        `SecondaryAddress set is ${secondaryAddress}. Reconnect your wallet and reset local storage. Then try again)`
      );
    }

    const txCount = await client.getTransactionCount({
      address: secondaryAddress as any,
    });

    const res = await ProposalsManager.postMessage(
      id,
      message,
      primaryAddress,
      secondaryAddress,
      txCount,
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .validatorsChannelContract
    );

    if (res !== null) {
      navigate("/proposal/l1-transaction/success");
    }

    return;
  };

  const [localStoragePrimaryWalletAddress] = useLocalStorage(
    LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY
  );

  const [relatedTx, setRelatedTx] = useState("");
  const [destination, setDestination] = useState("");
  const [milkomedaAssetId, setMilkomedaAssetId] = useState("");
  const [amount, setAmount] = useState("");
  const [wmainAmount, setWmainAmount] = useState("");
  const [primaryAddress, setPrimaryAddress] = useState(
    localStoragePrimaryWalletAddress
  );

  const { tokensList } = useTokensList();

  const [selectedToken, setSelectedToken] = useState<DropdownOption>();

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [isConnected, navigate]);

  useEffect(() => {
    if (!selectedToken) {
      setMilkomedaAssetId("");
      return;
    }

    setMilkomedaAssetId(selectedToken.id);
  }, [selectedToken]);

  const submitAllowed = !!primaryAddress && !!destination && !!wmainAmount;

  return (
    <Layout title="Dashboard">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageHeader
          title="L1 Transaction"
          description="Effortlessly create L1 transactions with our user-friendly form for
            seamless blockchain management."
        />
        <div className="container z-10 relative overflow-auto">
          <div className="mx-auto max-w-[600px]">
            <div className="space-y-4 mt-8">
              <Input
                required
                onInputChange={(val) => setPrimaryAddress(val)}
                value={primaryAddress}
                label="Primary wallet address"
                labelAddition={
                  !!localStoragePrimaryWalletAddress
                    ? "(pre-filled address)"
                    : ""
                }
              />
              <Input
                onInputChange={(val) => setRelatedTx(val)}
                value={relatedTx}
                label="Refunded transaction ID"
                placeholder="If there's one, please provide its hash, so validators have more context"
              />
              <Input
                required
                onInputChange={(val) => setDestination(val)}
                value={destination}
                label="Destination address"
                placeholder="addr.."
              />
              <Input
                required
                onInputChange={(val) => setWmainAmount(val)}
                value={wmainAmount}
                label="ADA amount"
                labelAddition="(min 3 ADA)"
                placeholder="3"
                enforceNumbers
              />
              <TextDropdown
                options={[
                  { id: "", label: "-" },
                  ...tokensList.map((token) => {
                    return {
                      id: token.assetID,
                      label: token?.symbol ?? "",
                    };
                  }),
                ]}
                selectedOption={selectedToken}
                label="Token"
                onOptionChange={(o) => setSelectedToken(o.id ? o : undefined)}
                placeholder="Select token"
                tallerYSpacing
              />
              {!!milkomedaAssetId && (
                <Input
                  onInputChange={(val) => setAmount(val)}
                  value={amount}
                  label={`${selectedToken?.label} amount`}
                  enforceNumbers
                />
              )}
            </div>
            <div className="mt-6">
              <Button
                onClick={async () => await submitL1TransactionProposal()}
                disabled={!submitAllowed}
              >
                Submit proposal
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default L1Transactionform;
