export enum TokenRegistryUpdateState {
  Added = "Added",
  Updated = "Updated",
  Removed = "Removed",
}

export interface RegistryEntry {
  state: TokenRegistryUpdateState;
  entry?: {
    tokenType: string;
    tokenContract: string;
    tokenId: string | null;
    minimumValue: string;
    mainChainDecimal: number;
  };
}

export interface TokenRegistryInterface {
  assetId: string;
  update: RegistryEntry;
}

//
export enum Version {
  V1,
}

export enum ActionType {
  L1TxInit, // header: 0000
  L1Tx,
  L1TxVote,
  L1TxConfirmed,
  Approve, // header: 1000
  RejectAndComment, // header: 1400
  L2TxInit, // header: 1800
  TokenRegistryUpdateInit,
  Msg,
  ValidatorUpdateProposal,
  FundsMigrationTxAdded,
  FundsMigrationTxSigned,
}

export enum ActionCode {
  Refund,
  WithdrawalOfStakingAw,
  MoveStargateFunds,
  RefundL2,
  TokenRegistryUpdate,
}

// chains
export type Chain = {
  id: number;
  name: string;
  network: string;
  nativeCurrency: {
    name: string;
    /** 2-6 characters long */
    symbol: string;
    decimals: number;
  };
  rpcUrls: {
    default: {
      http: string[];
      webSocket?: string[];
    };
    public: {
      http: string[];
      webSocket?: string[];
    };
  };
  blockExplorers?: {
    default: {
      name: string;
      url: string;
    };
  };
  testnet?: boolean;
};

export interface L2TxInterface {
  related_tx: null | string;
  sender: string;
  destination: string;
  asset_id: string;
  amount: string;
}

// ops: vec![bridge_ir::validator_set_update_proposal::Op::Add(
//   bridge_ir::SpendingKeyHash::new(
//       "0029c55571621fac329c785041a157f8ea45754cb2ec5006b9130229",
//   ),
//   // member1
//   bridge_ir::Address::new("0xFE3B557E8Fb62b89F4916B721be55cEb828dBd73"),
// )],
// export interface AddValidatorInterface {
//   mainchainPublicKey: string;
//   evmAddress: string;
// }

export interface AddValidatorInterface {
  Add: [
    number[],
    string,
    {
      evm_signature: {
        v: number;
        r: number[];
        s: number[];
      };
      cardano_signature: number[];
    }
  ];
}

// export interface RemoveValidatorInterface {
//   evmAddress: string;
// }

export interface RemoveValidatorInterface {
  Remove: [number[], string];
}

type ChangeValidatorInterface =
  | AddValidatorInterface
  | RemoveValidatorInterface;

export interface ValidatorChangeTxInterface {
  ops: ChangeValidatorInterface[];
  starting_block: number;
}

export interface Asset {
  asset_id: string;
  amount: number;
}

export interface BridgeTokenIndexerResponse {
  asset_id: string;
  tokenContract: string;
  token_type: string;
  minimum_value: string;
  timestamp: number;
  sidechain_decimals: number;
  mainchain_decimals: number;
  symbol: any;
  cardano_policy: any;
  cardano_fingerprint: any;
  tvl: any;
}

export interface BridgeToken {
  assetID: string;
  tokenContract: string;
  tokenType: string;
  minimumValue: string;
  timestamp: number;
  sidechainDecimals: number;
  mainchainDecimals: number;
  symbol: string | null;
  cardanoPolicy: null;
  cardanoFingerprint: null;
  tvl: string | null;
}

export interface L1TxInterface {
  related_tx: null | string;
  sender: string;
  destination: string;
  asset_id: string;
  amount: number;
  wmain_amount: number;
}
