import React, { TextareaHTMLAttributes } from "react";

interface TextAreaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
  label?: string;
  onTextAreaChange?: (value: string) => void;
}

const TextArea = ({ label, onTextAreaChange, ...props }: TextAreaProps) => {
  return (
    <div className="flex flex-col gap-1">
      {!!label && (
        <label className="text-12 text-gray-default uppercase tracking-wider">
          {label}
        </label>
      )}
      <textarea
        {...props}
        className="rounded-[12px] p-3 bg-dark-default focus:outline-none text-white text-14 font-semibold"
        onChange={(e) => onTextAreaChange?.(e.target.value)}
      />
    </div>
  );
};

export default TextArea;
