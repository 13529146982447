import React from "react";

import RequestDetailRow from "./RequestDetailRow";
import Link from "../Link";
import RequestDetailSection from "./RequestDetailSection";

import {
  mapActionStatusToMessage,
  mapActionTypeToMessage,
  mapChangeValidatorTypeToMessage,
} from "../../utils/requests";
import { RequestInterface } from "../../types/Request.interface";
import {
  getThousandsSeparatedNumber,
  mapNetworkIdWithName,
} from "../../utils/common";
import { ActionType } from "../../types/ActionType.enum";
import { ActionStatus } from "../../types/ActionStatus.enum";
import { useWalletClient } from "wagmi";
import config from "../../config/config.json";
import { ConfigType } from "../../config/config";
const cfg = config as ConfigType;

interface RequestDetailProps extends RequestInterface {}

export const getBlockExplorerLinkToConfirmation = (
  actionType: ActionType,
  confirmationId: string
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const walletClient = useWalletClient();

  if (actionType === ActionType.L1_TRANSACTION) {
    return `${
      cfg[mapNetworkIdWithName(walletClient.data?.chain.id)].mainchainExplorer
    }/tx/${confirmationId}`;
  } else if (actionType === ActionType.L2_TRANSACTION) {
    return `${
      cfg[mapNetworkIdWithName(walletClient.data?.chain.id)].evmExplorer
    }/tx/${confirmationId}`;
  }
};

export const getEnumToListedString = (enumValue: any) => {
  return Object.values(enumValue).join(", ");
};

const RequestDetail = ({
  id,
  mainchainAssetId,
  sidechainTokenId,
  tokenName,
  totalSupply,
  requiredSupply,
  decimalPrecisionCardano,
  decimalPrecisionMilkomeda,
  mainchainExplorerLink,
  evmExplorerLink,
  tokenContract,
  refundedTransactionId,
  destinationAddress,
  token1Name,
  token1Value,
  token2Name,
  token2Value,
  actionType,
  actionStatus,
  changeValidatorSet,
  startingBlock,
  sidechainTokenAmount,
  confirmationId,
  confirmationTimestamp,
  failedReason,
}: RequestDetailProps) => {
  return (
    <div className="space-y-3 md:space-y-4">
      <RequestDetailSection>
        <RequestDetailRow
          title="Request ID"
          tooltip="Unique request ID of initial proposal. This is id by which the proposal is recognized in further operations."
          value={id}
          boldValue
        />
        <RequestDetailRow
          title="Request status"
          tooltip={`Status of the request. Possible values are: ${getEnumToListedString(
            ActionStatus
          )}`}
          value={mapActionStatusToMessage[actionStatus]}
          boldValue
        />

        <RequestDetailRow
          title="Request type"
          tooltip={`Type of the request. Possible values are: ${getEnumToListedString(
            ActionType
          )} `}
          value={mapActionTypeToMessage[actionType]}
          boldValue
        />

        {!!failedReason && (
          <RequestDetailRow
            title="Failure reason"
            tooltip="Reason for why the transaction was failed. It only shows up, if the request was recognized as `failed`"
            value={failedReason}
          />
        )}

        {!!confirmationId && (
          <RequestDetailRow
            title="Confirmation"
            tooltip="Confirmation event from one of the chains (for L1 it's Cardano tx, for L2 it's EVM transaction), which presents proof of request successful execution"
            value={
              <a
                href={getBlockExplorerLinkToConfirmation(
                  actionType,
                  confirmationId
                )}
                target="_blank"
                rel="noreferrer"
              >
                {confirmationId}
              </a>
            }
          />
        )}

        {!!confirmationTimestamp && (
          <RequestDetailRow
            title="Confirmation time"
            tooltip="Confirmation event timestamp, which shows time when the confirmation time appeared on one of the chainsn"
            value={confirmationTimestamp}
          />
        )}
        {!!mainchainAssetId && (
          <RequestDetailRow
            title="Mainchain asset ID"
            tooltip="Mainchain assset id (Cardano fingerprint)"
            value={mainchainAssetId}
          />
        )}

        {!!startingBlock && (
          <RequestDetailRow
            title="Starting block"
            tooltip="When changing validators set, the execution of the change is always scheduled to happen on specific block number."
            value={startingBlock}
          />
        )}
        {!!sidechainTokenId && (
          <RequestDetailRow
            title="Sidechain token ID"
            tooltip="Sidechain token ID, which is generated before token deployment on the EVM side. Consists ofthe part of policyId and padded zeros. For native token it's all 0s"
            value={sidechainTokenId}
          />
        )}

        {!!sidechainTokenAmount && (
          <RequestDetailRow
            title="Sidechain token amount"
            tooltip="Sidechain token amount"
            value={sidechainTokenAmount}
          />
        )}

        {!!tokenName && (
          <RequestDetailRow
            title="Token name"
            tooltip="Name of the token on the network"
            value={tokenName}
          />
        )}
        {!!totalSupply && (
          <RequestDetailRow
            title="Total supply of the token on mainchain"
            tooltip="Total supply of the token on mainchain."
            value={`${getThousandsSeparatedNumber(totalSupply)} ${tokenName}`}
          />
        )}
        {!!requiredSupply && (
          <RequestDetailRow
            title="Required supply of the token on Milkomeda"
            tooltip="Required supply of the token on Milkomeda"
            value={`${getThousandsSeparatedNumber(
              requiredSupply
            )} ${tokenName}`}
          />
        )}
        {!!decimalPrecisionCardano && (
          <RequestDetailRow
            title="Decimal precision on mainchain Cardano"
            tooltip="This is how many decimals the token has on Cardano"
            value={decimalPrecisionCardano}
          />
        )}
        {!!decimalPrecisionMilkomeda && (
          <RequestDetailRow
            title="Decimal precision on Milkomeda"
            tooltip="This is how many decimals the token has on EVM (Milkomeda side)"
            value={decimalPrecisionMilkomeda}
          />
        )}
        {!!mainchainExplorerLink && (
          <RequestDetailRow
            title="Mainchain explorer link to the token"
            tooltip="Mainchain explorer link to the token"
            value={
              <Link to={mainchainExplorerLink} target="_blank">
                link
              </Link>
            }
          />
        )}
        {!!evmExplorerLink && (
          <RequestDetailRow
            title="EVM explorer link to the token"
            tooltip="EVM explorer link to the token"
            value={
              <Link to={evmExplorerLink} target="_blank">
                link
              </Link>
            }
          />
        )}
        {!!tokenContract && (
          <RequestDetailRow
            title="Token contract"
            tooltip="Token contract on the EVM side"
            value={tokenContract}
          />
        )}
        {!!refundedTransactionId && (
          <RequestDetailRow
            title="Refunded transaction ID"
            tooltip="This is transaction hash of a transaction that failed initially and was the reason for assets transfer from the validators to the given address. This is optional field when defining transaction."
            value={refundedTransactionId}
          />
        )}
        {!!destinationAddress && (
          <RequestDetailRow
            title="Destination address"
            tooltip="Address to which the transaction is/was sent"
            value={destinationAddress}
          />
        )}
        {!!token1Name && token1Value !== undefined && token1Value !== null && (
          <RequestDetailRow
            title="Tokens"
            tooltip="List of tokens with amounts that are sent in L1 transaction when sending tokens different than ADA. List contains of the token and ADA."
            value={
              <div className="leading-7">
                <p>
                  {getThousandsSeparatedNumber(token1Value)}&nbsp;
                  {token1Name}
                </p>
                {!!token2Name &&
                  token2Value !== undefined &&
                  token2Value !== null && (
                    <p>
                      {getThousandsSeparatedNumber(token2Value)}&nbsp;
                      {token2Name}
                    </p>
                  )}
              </div>
            }
          />
        )}
      </RequestDetailSection>
      {actionType === ActionType.CHANGE_VALIDATOR_SET &&
        !!changeValidatorSet &&
        changeValidatorSet.map((item, index) => (
          <RequestDetailSection
            key={index}
            title={mapChangeValidatorTypeToMessage[item.type]}
          >
            {!!item.evmAddress1 && (
              <RequestDetailRow
                title={
                  !!item.evmAddress1 && !!item.evmAddress2
                    ? "EVM address 1 (Bridge address)"
                    : "EVM address"
                }
                tooltip="Validators main address (used by the bridge)"
                value={item.evmAddress1}
              />
            )}
            {/* {!!item.evmAddress2 && (
              <RequestDetailRow
                title="EVM address 2 (secondary address)"
                tooltip="Validators secondary address (used by the validator in the dApp)"
                value={item.evmAddress1}
              />
            )} */}
            {!!item.publicKey && (
              <RequestDetailRow
                title="Mainchain (Cardano) public key"
                tooltip="Cardano public key, based on which stargate is calculated"
                value={item.publicKey}
              />
            )}

            {!!item.evmSignedMessage && (
              <RequestDetailRow
                title="EVM signed message"
                tooltip="Proof that validator has access to their primary evm key"
                value={item.evmSignedMessage}
              />
            )}

            {!!item.cardanoSignedMessage && (
              <RequestDetailRow
                title="Proof that validator has access to their primary cardano key"
                tooltip="Cardano public key, based on which stargate is calculated"
                value={item.cardanoSignedMessage}
              />
            )}

            {!!item.validatorToReplace && (
              <RequestDetailRow
                title="Current validator to be replaced"
                tooltip="Address of a validator that should be replaced"
                value={item.validatorToReplace}
              />
            )}
          </RequestDetailSection>
        ))}
    </div>
  );
};

export default RequestDetail;
