import classNames from "classnames";

import { ComponentWithChildren } from "../types/ComponentWithChildren.interface";

type TooltipMaxWidth = "S" | "M" | "L";
type MobileAlign = "left" | "right" | "center";
type Orientation = "top" | "bottom";

interface TooltipProps extends ComponentWithChildren {
  text: string;
  maxWidth?: TooltipMaxWidth;
  mobileAlign?: MobileAlign;
  orientation?: Orientation;
  className?: string;
  onTooltipMouseLeave?: () => void;
}

const Tooltip = ({
  children,
  text,
  maxWidth = "L",
  mobileAlign = "center",
  orientation = "top",
  className,
  onTooltipMouseLeave,
}: TooltipProps) => {
  const getMaxWidthStyle = () => {
    if (maxWidth === "M") {
      return "w-[150px] rounded-[12px]";
    }
    if (maxWidth === "S") {
      return "w-[100px] rounded-[8px]";
    }
    return "w-[200px] rounded-[16px]";
  };

  const getArrowStyle = (
    mobileAlignment: MobileAlign,
    orientation: Orientation
  ) => {
    const commonStyles =
      "after:content-[''] after:absolute after:border-t-[8px] after:border-t-transparent after:border-r-[8px] after:border-r-dark-light after:border-b-[8px] after:border-b-transparent after:w-[10px] after:h-[5px]";

    const orientationStyles =
      orientation === "bottom"
        ? "after:-top-[10px] after:rotate-90"
        : "after:-bottom-[10px] after:-rotate-90";

    const mobileOrientationStyles = () => {
      const commonNonCenterStyles =
        "md:left-1/2 md:transform md:-translate-x-1/2 md:after:mx-auto md:after:-rotate-90 after:-bottom-[8px] md:after:-bottom-[12px]";

      if (mobileAlignment === "left") {
        return classNames(
          commonNonCenterStyles,
          "left-1/2 max-md:rounded-bl-[0px] after:left-0 md:after:right-0 after:rotate-180"
        );
      }

      if (mobileAlignment === "right") {
        return classNames(
          commonNonCenterStyles,
          "right-1/2 max-md:rounded-br-[0px] after:right-0 md:after:left-0 after:rotate-135"
        );
      }

      return "left-1/2 transform -translate-x-1/2";
    };

    return classNames(
      commonStyles,
      orientationStyles,
      mobileOrientationStyles(),
      "after:left-0 after:right-0 after:mx-auto"
    );
  };

  const getOrientationStyle = () => {
    if (orientation === "bottom") {
      return "bottom-0 m-auto translate-y-[120%]";
    }

    return "top-0 m-auto -translate-y-[115%]";
  };

  return (
    <div
      className={classNames(
        "relative",
        "group/tooltip",
        "cursor-pointer",
        className
      )}
      onMouseLeave={() => {
        onTooltipMouseLeave?.();
      }}
    >
      <div>{children}</div>
      <p
        className={classNames(
          "hidden group-hover/tooltip:block",
          "absolute",
          getMaxWidthStyle(),
          getOrientationStyle(),
          "bg-dark-light",
          "p-3",
          "text-12 font-light text-white text-center",
          "shadow-[0px_2px_8px_rgba(0,0,0,0.6)]",
          getArrowStyle(mobileAlign, orientation)
        )}
      >
        {text}
      </p>
    </div>
  );
};

export default Tooltip;
