import { useEffect, useState } from "react";

import { ActionStatus } from "../types/ActionStatus.enum";
import { ActionType } from "../types/ActionType.enum";
import { RequestInterface } from "../types/Request.interface";

import { getMyTasks, getRequests } from "../utils/api";
import { useWalletClient } from "wagmi";
import { mapNetworkIdWithName } from "../utils/common";
import { LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY } from "../constants";
import { useLocalStorage } from "./useLocalStorage";

export const useFilteredRequests = (
  perPage: number,
  currentPage: number,
  statusFilter: ActionStatus | null,
  typeFilter: ActionType | null,
  proposerAddress?: string,
  id?: string,
  shouldRefetch?: boolean
) => {
  const [requests, setRequests] = useState<RequestInterface[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const walletClient = useWalletClient();

  useEffect(() => {
    if (shouldRefetch === false) {
      return;
    }

    (async () => {
      setIsLoading(true);

      const requests = await getRequests(
        statusFilter,
        typeFilter,
        proposerAddress,
        currentPage,
        perPage,
        id,
        mapNetworkIdWithName(walletClient?.data?.chain.id)
      );

      if (requests?.data !== undefined) {
        setRequests(requests.data);
      }

      if (requests?.maxPage !== undefined) {
        setMaxPage(requests.maxPage);
      }

      if (requests?.allCount !== undefined) {
        setAllCount(requests.allCount);
      }

      setIsLoading(false);
    })();
  }, [
    currentPage,
    perPage,
    proposerAddress,
    statusFilter,
    typeFilter,
    id,
    shouldRefetch,
    walletClient?.data?.chain.id,
  ]);

  return {
    maxPage,
    currentPage,
    requests,
    allCount: allCount,
    isLoading,
  };
};

export const useMyTasks = (
  address: string,
  perPage: number,
  currentPage: number,
  typeFilter?: ActionType | null,
  id?: string,
  shouldRefetch?: boolean
) => {
  const [requests, setRequests] = useState<RequestInterface[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const walletClient = useWalletClient();

  const [localStoragePrimaryWalletAddress] = useLocalStorage(
    LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY
  );

  useEffect(() => {
    if (shouldRefetch === false) {
      return;
    }

    (async () => {
      setIsLoading(true);

      const requests = await getMyTasks(
        address,
        currentPage,
        perPage,
        typeFilter,
        id,
        mapNetworkIdWithName(walletClient.data?.chain.id),
        localStoragePrimaryWalletAddress
      );

      if (requests?.data !== undefined) {
        setRequests(requests.data);
      }

      if (requests?.maxPage !== undefined) {
        setMaxPage(requests.maxPage);
      }

      if (requests?.allCount !== undefined) {
        setAllCount(requests.allCount);
      }

      setIsLoading(false);
    })();
  }, [
    currentPage,
    perPage,
    address,
    typeFilter,
    id,
    shouldRefetch,
    walletClient.data?.chain.id,
    localStoragePrimaryWalletAddress,
  ]);

  return {
    maxPage,
    currentPage,
    requests,
    allCount: allCount,
    isLoading,
  };
};

export const useRequestDetail = (id: string | null | undefined) => {
  const [requestData, setRequestData] = useState<RequestInterface>();
  const [isLoading, setIsLoading] = useState(true);

  const walletClient = useWalletClient();

  useEffect(() => {
    if (!id) {
      return;
    }

    (async () => {
      setIsLoading(true);
      const requests = await getRequests(
        null,
        null,
        null,
        null,
        null,
        id,
        mapNetworkIdWithName(walletClient?.data?.chain.id)
      );

      if (!!requests?.data && requests?.allCount === 1) {
        setRequestData(requests.data[0]);
      }
      setIsLoading(false);
    })();
  }, [id, walletClient?.data?.chain.id]);

  return {
    requestData,
    isLoading,
  };
};
