import CopyAddress from "./CopyAddress";

import { ReactComponent as AccountAvatar } from "../assets/icon-account-avatar.svg";

import { middleEllipsis } from "../utils/address";

interface AccountAddressFieldProps {
  address: string;
}

const AccountAddressField = ({ address }: AccountAddressFieldProps) => {
  return (
    <div className="bg-dark-darker rounded-[12px] py-3 px-4 flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <AccountAvatar className="w-[16px] h-[16px] rounded-[50%]" />
        <p className="text-white text-14 font-medium">
          {middleEllipsis(address)}
        </p>
      </div>
      <CopyAddress address={address} />
    </div>
  );
};

export default AccountAddressField;
