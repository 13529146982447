import ExternalLinkButton from "./ExternalLinkButton";

interface DownloadButtonProps {
  smallVariant?: boolean;
  walletName?: string;
}

export const walletDownloadLinksMap: Record<string, string> = {
  MetaMask: "https://metamask.io/download/",
};

const DownloadButton = ({
  smallVariant = false,
  walletName,
}: DownloadButtonProps) => {
  return (
    <ExternalLinkButton
      href={walletName ? walletDownloadLinksMap[walletName] : ""}
      target="_blank"
      smallVariant={smallVariant}
      className="flex items-center justify-center gap-2"
    >
      Download wallet
    </ExternalLinkButton>
  );
};

export default DownloadButton;
