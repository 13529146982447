import classNames from "classnames";
import { Link as RLink, LinkProps as RLinkProps } from "react-router-dom";

export interface LinkProps extends RLinkProps {}

const Link = ({
  className: additionalClasses,
  children,
  ...restProps
}: LinkProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <RLink
      {...restProps}
      className={classNames(
        "text-orange-dark font-medium inline",
        "cursor-pointer hover:underline",
        additionalClasses
      )}
    >
      {children}
    </RLink>
  );
};

export default Link;
