import classNames from "classnames";
import { AnchorHTMLAttributes } from "react";

import { IconComponentType } from "../types/IconComponent.type";

export interface ExternalLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  icon?: IconComponentType;
}

const ExternalLink = ({
  icon,
  className: additionalClasses,
  children,
  ...restProps
}: ExternalLinkProps) => {
  const IconComponent = icon;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      {...restProps}
      target="_blank"
      className={classNames(
        "text-orange-dark font-bold text-12",
        "cursor-pointer hover:underline",
        "flex items-center",
        additionalClasses
      )}
    >
      {children}
      {IconComponent && <IconComponent className="fill-current ml-1" />}
    </a>
  );
};

export default ExternalLink;
