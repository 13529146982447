import classNames from "classnames";
import React, { InputHTMLAttributes } from "react";

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  label?: string;
  labelAddition?: string;
  trimWhiteSpaces?: boolean;
  onInputChange?: (sanitizedValue: string, rawValue: string) => void;
  enforceNumbers?: boolean;
  enforceWholeNumbers?: boolean;
  errorMessage?: string | undefined;
}

const Input = ({
  label,
  labelAddition,
  onInputChange,
  trimWhiteSpaces = true,
  enforceNumbers = false,
  enforceWholeNumbers = false,
  errorMessage,
  ...props
}: InputProps) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (enforceNumbers) {
      if (isNaN(Number(value))) {
        return;
      }

      if (enforceWholeNumbers) {
        if (value.includes(".") || value.includes(",")) {
          return;
        }
      }
    }

    onInputChange?.(trimWhiteSpaces ? value.trim() : value, value);
  };

  return (
    <div className="flex flex-col gap-1">
      {!!label && (
        <label className="text-12 text-gray-default uppercase tracking-wider">
          {label}
          {!!labelAddition && (
            <span className="text-10">&nbsp;{labelAddition}</span>
          )}
          {props.required && (
            <span className="text-orange-default">&nbsp;*</span>
          )}
        </label>
      )}
      <input
        {...props}
        className={classNames(
          "rounded-[12px] p-3 bg-dark-default focus:outline-none text-white text-14 font-semibold disabled:cursor-not-allowed disabled:bg-dark-light",
          "border",
          !!errorMessage ? "border-red-default" : "border-gray-default"
        )}
        onChange={(e) => handleInputChange(e)}
      />
      {!!errorMessage && (
        <span className="text-red-default text-12 text-right mt-1">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default Input;
