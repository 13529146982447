// import Footer from "./Footer";
import React from "react";
import { Helmet } from "react-helmet-async";

import Navbar from "./Navbar";
import Footer from "./Footer";

import { ComponentWithChildren } from "../types/ComponentWithChildren.interface";

interface LayoutProps extends ComponentWithChildren {
  wrapperClassname?: string;
  title?: string;
}

const meta = {
  title: "Validators Coordination",
  description: "Ut in minim tempor fugiat sit.",
  image: `${process.env.REACT_APP_SITE_URL}/images/og.jpg`,
  type: "website",
};

const Layout = ({ children, wrapperClassname, title }: LayoutProps) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {!!title ? `${title} | Validators Coordination dApp` : meta.title}
        </title>
        <meta name="description" content={meta.description} />
        <meta name="image" content={meta.image} />
        <meta property="og:type" content={meta.type} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Milkomeda" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#f07d00" />
        <meta name="msapplication-TileColor" content="#f07d00" />
        <meta name="theme-color" content="#f07d00" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="manifest" href="/manifest.json" />
        {/* <meta name="twitter:image" content={meta.image} /> */}
      </Helmet>
      <Navbar />
      <main className={wrapperClassname}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
