import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import AssetFingerprint from "@emurgo/cip14-js";
import { pad } from "viem";

import Layout from "../../components/Layout";
import Button from "../../components/Button";
import Input from "../../components/Input";
import PageHeader from "../../components/PageHeader";

import { LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY } from "../../constants";
import {
  TokenRegistryInterface,
  TokenRegistryUpdateState,
} from "../../types/ProposalTypes";
import { ProposalsManager } from "../../proposalsManager";
import { useLocalStorage } from "../../hooks/useLocalStorage";

import config from "../../config/config.json";
import { ConfigType } from "../../config/config";
import { mapNetworkIdWithName } from "../../utils/common";
const cfg = config as ConfigType;

function calculateMilkomedaAssetId(fingerprint: string): string | null {
  try {
    const assetFingerprint = AssetFingerprint.fromBech32(fingerprint);
    const fingerprintHash = assetFingerprint.hash();

    // we need to add 0x for pad()
    const paddedMilkomedaAssetId = pad(`0x${fingerprintHash}`, {
      dir: "right",
    });

    // now we need to remove 0x after padding was done
    return paddedMilkomedaAssetId.slice(2);
  } catch (e) {
    // TODO: Error handling to show meaningful error to the user
    console.error(e);
    return null;
    // TODO: triggers the ErrorDisplay component in react-router-dom
    // throw e;
  }
}

const AddTokenForm = () => {
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const walletClient = useWalletClient();
  const client = usePublicClient();

  const submitTokenRegistryUpdateProposal = async () => {
    const tokenRegistryPayload: TokenRegistryInterface = {
      assetId: milkomedaAssetId,
      update: {
        state: TokenRegistryUpdateState.Added,
        entry: {
          tokenType: "ERC20",
          tokenContract: tokenContract,
          tokenId: null,
          minimumValue: minimumValue,
          // TODO: Handle conversion to Number and the error handling
          mainChainDecimal: Number(mainChainDecimal),
        },
      },
    };

    const { id, message } =
      ProposalsManager.buildTRMessage(tokenRegistryPayload);

    const secondaryAddress = walletClient.data?.account.address.toString();
    if (!secondaryAddress) {
      throw Error(
        `SecondaryAddress set is ${secondaryAddress}. Reconnect your wallet and reset local storage. Then try again)`
      );
    }

    const txCount = await client.getTransactionCount({
      address: secondaryAddress as any,
    });

    const res = await ProposalsManager.postMessage(
      id,
      message,
      primaryAddress,
      secondaryAddress,
      txCount,
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .validatorsChannelContract
    );

    if (res !== null) {
      navigate("/proposal/add-token/success");
    }

    return;
  };

  const [localStoragePrimaryWalletAddress] = useLocalStorage(
    LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY
  );

  const [milkomedaAssetId, setMilkomedaAssetId] = useState("");
  const [cardanoFingerprint, setCardanoFingerprint] = useState("");
  const [symbol, setSymbol] = useState("");
  const [tokenContract, setTokenContract] = useState("");
  const [minimumValue, setMinValue] = useState("");
  const [mainChainDecimal, setMainchainDecimals] = useState("");
  const [primaryAddress, setPrimaryAddress] = useState(
    localStoragePrimaryWalletAddress
  );

  const [fingerPrintErrorMessage, setFingerPrintErrorMessage] = useState<
    string | undefined
  >(undefined);

  const submitAllowed =
    !!milkomedaAssetId &&
    !!cardanoFingerprint &&
    !!primaryAddress &&
    !!tokenContract &&
    !!symbol &&
    !!minimumValue &&
    !!mainChainDecimal;

  useEffect(() => {
    if (!isConnected) {
      navigate("/");
    }
  }, [isConnected, navigate]);

  useEffect(() => {
    if (!cardanoFingerprint) {
      return;
    }

    const calculatedMilkomedaAssetId =
      calculateMilkomedaAssetId(cardanoFingerprint);

    if (!!calculatedMilkomedaAssetId) {
      setMilkomedaAssetId(calculatedMilkomedaAssetId);
      setFingerPrintErrorMessage(undefined);
    } else {
      setFingerPrintErrorMessage("Invalid fingerprint");
    }
  }, [cardanoFingerprint]);

  return (
    <Layout title="Dashboard">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageHeader
          title="Add token"
          description="Easily add tokens to our system with our simple and efficient form."
        />
        <div className="container z-10 relative overflow-auto">
          <div className="mx-auto max-w-[600px]">
            <div className="space-y-4 mt-8">
              <Input
                required
                onInputChange={(val) => setPrimaryAddress(val)}
                value={primaryAddress}
                label="Primary wallet address"
                labelAddition={
                  !!localStoragePrimaryWalletAddress
                    ? "(pre-filled address)"
                    : ""
                }
              />
              <Input
                required
                onInputChange={(val) => setCardanoFingerprint(val)}
                value={cardanoFingerprint}
                label="Cardano fingerprint"
                errorMessage={fingerPrintErrorMessage}
              />
              <Input
                onInputChange={(val) => setMilkomedaAssetId(val)}
                value={milkomedaAssetId}
                placeholder=""
                label="Milkomeda Asset ID"
                labelAddition="(calculated)"
                disabled
                readOnly
              />
              <Input
                required
                onInputChange={(val) => setTokenContract(val)}
                value={tokenContract}
                placeholder="0x000.."
                label="Token Contract"
              />
              <Input
                required
                onInputChange={(val) => setTokenContract(val)}
                value={"ERC20"}
                label="Token Type"
                readOnly
                disabled
              />
              <Input
                required
                onInputChange={(val) => setSymbol(val)}
                value={symbol}
                label="Symbol"
              />
              <Input
                required
                onInputChange={(val) => setMinValue(val)}
                value={minimumValue}
                label="Minimum value"
                placeholder="1000.."
                enforceNumbers
              />
              <Input
                required
                onInputChange={(val) => setMainchainDecimals(val)}
                value={mainChainDecimal}
                label="Mainchain decimals"
                placeholder="18"
                enforceNumbers
                enforceWholeNumbers
              />
            </div>
            <div className="mt-6">
              <Button
                onClick={async () => await submitTokenRegistryUpdateProposal()}
                disabled={!submitAllowed}
              >
                Submit proposal
              </Button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AddTokenForm;
