import { useEffect, useState } from "react";

function getStorageValue(key: string, defaultValue: any) {
  const value = localStorage.getItem(key);

  return !!value && value !== "undefined" ? JSON.parse(value) : defaultValue;
}

export const useLocalStorage = (key: string, defaultValue?: any) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue ?? "");
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
