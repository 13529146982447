import React from "react";

import PageGradient from "./PageGradient";

interface PageHeaderProps {
  title: string;
  description?: string;
  withGradient?: boolean;
}

const PageHeader = ({
  title,
  description,
  withGradient = true,
}: PageHeaderProps) => {
  return (
    <>
      {withGradient && <PageGradient />}
      <div className="container z-10 relative">
        <h1 className="text-20 text-white font-bold text-center">{title}</h1>
        {!!description && (
          <h2 className="text-12 text-gray-light font-bold text-center mt-2">
            {description}
          </h2>
        )}
      </div>
    </>
  );
};

export default PageHeader;
