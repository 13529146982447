import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { WagmiConfig } from "wagmi";
import "./index.css";

import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./pages/Home";
import MyTasks from "./pages/MyTasks";
import AllRequests from "./pages/AllRequests";
import Dashboard from "./pages/Dashboard";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/auth";
import TaskDetailPage from "./pages/TaskDetailPage";
import RequestDetailPage from "./pages/RequestDetailPage";
import AddTokenform from "./pages/proposals/AddToken";
import RemoveTokenForm from "./pages/proposals/RemoveToken";
import L2TransactionForm from "./pages/proposals/L2Transaction";
import L1Transactionform from "./pages/proposals/L1Transaction";
import ErrorDisplay from "./components/ErrorDisplay";
import ChangeValidatorSet from "./pages/proposals/ChangeValidatorSet";
import ProposalSuccess from "./pages/proposals/ProposalSuccess";
import { ProposalSuccessEnum } from "./types/ProposalSuccess.enum";

import wagmiConfig from "./utils/wagmi";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tasks"
        element={
          <ProtectedRoute>
            <MyTasks />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tasks/:id"
        element={
          <ProtectedRoute>
            <TaskDetailPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/requests"
        element={
          <ProtectedRoute>
            <AllRequests />
          </ProtectedRoute>
        }
      />
      <Route
        path="/requests/:id"
        element={
          <ProtectedRoute>
            <RequestDetailPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proposal/add-token"
        element={
          <ProtectedRoute>
            <AddTokenform />
          </ProtectedRoute>
        }
        errorElement={<ErrorDisplay />}
      />
      <Route
        path="/proposal/remove-token"
        element={
          <ProtectedRoute>
            <RemoveTokenForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proposal/l2-transaction"
        element={
          <ProtectedRoute>
            <L2TransactionForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proposal/l1-transaction"
        element={
          <ProtectedRoute>
            <L1Transactionform />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proposal/change-validator-set"
        element={
          <ProtectedRoute>
            <ChangeValidatorSet />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proposal/add-token/success"
        element={
          <ProtectedRoute>
            <ProposalSuccess successType={ProposalSuccessEnum.ADD_TOKEN} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proposal/remove-token/success"
        element={
          <ProtectedRoute>
            <ProposalSuccess successType={ProposalSuccessEnum.REMOVE_TOKEN} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proposal/l1-transaction/success"
        element={
          <ProtectedRoute>
            <ProposalSuccess successType={ProposalSuccessEnum.L1_TRANSACTION} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proposal/l2-transaction/success"
        element={
          <ProtectedRoute>
            <ProposalSuccess successType={ProposalSuccessEnum.L2_TRANSACTION} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/proposal/change-validator-set/success"
        element={
          <ProtectedRoute>
            <ProposalSuccess
              successType={ProposalSuccessEnum.CHANGE_VALIDATOR_SET}
            />
          </ProtectedRoute>
        }
      />
    </>
  )
);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <WagmiConfig config={wagmiConfig}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </WagmiConfig>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
