import classNames from "classnames";
import { ReactElement, ReactNode, useEffect, useState } from "react";

import { ReactComponent as DownCaret } from "../assets/icon-arrow-down.svg";
import { useOutsideClick } from "../hooks/useOutsideClick";

export interface DropdownOption {
  label: string;
  id: string;
}

export interface TextDropdownProps {
  options: DropdownOption[];
  onOptionChange?: (selected: DropdownOption) => void;
  optionLabel?: string;
  placeholder?: string;
  label?: string;
  required?: boolean;
  selectedOption?: DropdownOption;
  selectedOptionLabel?: ReactElement | ReactElement[] | ReactNode | ReactNode[];
  controlledSelectedOption?: boolean;
  disabled?: boolean;
  additionalStartElement?:
    | ReactElement
    | ReactElement[]
    | ReactNode
    | ReactNode[];
  tallerYSpacing?: boolean;
}

const TextDropdown = ({
  options,
  onOptionChange,
  optionLabel,
  placeholder,
  label,
  required,
  additionalStartElement,
  selectedOptionLabel,
  selectedOption: propSelectedOption,
  controlledSelectedOption,
  disabled,
  tallerYSpacing = false,
}: TextDropdownProps) => {
  const ref = useOutsideClick(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const [selectedOption, setSelectedOption] = useState(
    propSelectedOption ?? options[0]
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!controlledSelectedOption || !propSelectedOption) {
      return;
    }

    setSelectedOption(propSelectedOption);
  }, [controlledSelectedOption, propSelectedOption]);

  return (
    <div
      className={classNames(
        "relative w-full",
        disabled && "pointer-events-none opacity-50"
      )}
      ref={ref}
    >
      {!!label && (
        <label className="text-12 text-gray-default uppercase tracking-wider">
          {label}
          {required && <span className="text-orange-default">&nbsp;*</span>}
        </label>
      )}
      <div
        className={classNames(
          "border rounded-[12px] flex items-center justify-between pl-3 pr-2 cursor-pointer",
          tallerYSpacing ? "py-[10px]" : "py-2",
          disabled ? "border-border-default" : "border-border-light"
        )}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <div className="flex items-center gap-2">
          {additionalStartElement && (
            <div className="w-[24px] h-[24px] flex items-center justify-center">
              {additionalStartElement}
            </div>
          )}
          {!!selectedOption ? (
            <div className="text-white text-14 flex items-center space-x-1">
              {optionLabel && (
                <span className="text-gray-light font-medium">
                  {optionLabel}
                </span>
              )}
              {selectedOptionLabel ?? <p>{selectedOption.label}</p>}
            </div>
          ) : (
            <div className="text-gray-default text-14 flex items-center space-x-1">
              <p>{placeholder}</p>
            </div>
          )}
        </div>
        <button
          className={classNames(
            "w-[24px] h-[24px] flex items-center justify-center",
            isOpen ? "rotate-180" : "rotate-0"
          )}
          aria-label="Open dropdown"
        >
          <DownCaret />
        </button>
      </div>
      {isOpen && (
        <ul className="absolute top-[100%] left-0 right-0 w-full bg-dark-light rounded-[8px] border border-border-light z-20">
          {options.map((option) => (
            <li
              key={option.id}
              onClick={() => {
                setSelectedOption(option);
                onOptionChange?.(option);
                setIsOpen(false);
              }}
              className={classNames(
                "hover:bg-option",
                option.id === (propSelectedOption?.id ?? selectedOption?.id) &&
                  "bg-option",
                "cursor-pointer",
                "text-white text-14",
                "px-3 py-2",
                "first-of-type:rounded-tl-[8px] first-of-type:rounded-tr-[8px]",
                "last-of-type:rounded-bl-[8px] last-of-type:rounded-br-[8px]"
              )}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TextDropdown;
