import { useState, useEffect } from "react";

import {
  BridgeToken,
  BridgeTokenIndexerResponse,
} from "../types/ProposalTypes";
import config from "../config/config.json";
import { ConfigType } from "../config/config";
import { TOKENS_ASSET_ID_BLACKLIST } from "../constants";

import { useWalletClient } from "wagmi";
import { mapNetworkIdWithName } from "../utils/common";
const cfg = config as ConfigType;

interface UseTokensListResult {
  tokensList: BridgeToken[];
}

const useTokensList = (includeADAToken?: boolean): UseTokensListResult => {
  const [tokensList, setTokensList] = useState<BridgeToken[]>([]);
  const walletClient = useWalletClient();

  useEffect(() => {
    const getTokensList = async () => {
      try {
        const res = await fetch(
          `${
            cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
              .indexerEndpointBaseUrl
          }/assets?active=true`
        );

        if (!res.ok) {
          throw new Error("Failed to fetch tokens list");
        }

        const response: BridgeTokenIndexerResponse[] = await res.json();
        let tokens: BridgeToken[] = [];

        for (let token of response) {
          if (!TOKENS_ASSET_ID_BLACKLIST.includes(token.asset_id)) {
            tokens.push({
              assetID: token.asset_id,
              tokenContract: token.tokenContract,
              tokenType: token.token_type,
              minimumValue: token.minimum_value,
              timestamp: token.timestamp,
              sidechainDecimals: token.sidechain_decimals,
              mainchainDecimals: token.mainchain_decimals,
              symbol: token.symbol,
              cardanoPolicy: token.cardano_policy,
              cardanoFingerprint: token.cardano_policy,
              tvl: token.tvl,
            });
          }
        }

        // TODO: Check if this object is correct with its values
        if (includeADAToken) {
          tokens.push({
            assetID:
              "0000000000000000000000000000000000000000000000000000000000000000",
            tokenContract: "0x0000000000000000000000000000000000000000",
            tokenType: "WMAIN",
            minimumValue: "3000000000000000000",
            timestamp: 1680278440,
            sidechainDecimals: 18,
            mainchainDecimals: 6,
            symbol: "ADA",
            cardanoPolicy: null,
            cardanoFingerprint: null,
            tvl: null,
          });
        }

        setTokensList(tokens);
      } catch (e) {
        const err = e as Error;
        console.error(err.message);
      }
    };

    getTokensList();
  }, [includeADAToken, walletClient?.data?.chain.id]);

  return { tokensList };
};

export default useTokensList;
