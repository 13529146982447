import React from "react";
import { useConnect } from "wagmi";
import { useNavigate } from "react-router-dom";

import Spinner from "./Spinner";
import Button from "./Button";
import DownloadButton from "./DownloadButton";

import {
  LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY,
  walletIconMap,
} from "../constants";
import { useLocalStorage } from "../hooks/useLocalStorage";

import { ReactComponent as RightArrow } from "../assets/icon-arrow-right-short.svg";

const DescriptiveErrorMessage = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div className="text-center">
    <h1 className="text-white text-18 font-medium mb-1">{title}</h1>
    <p className="text-gray-light text-14">{description}</p>
  </div>
);

const ConnectWallet = () => {
  const navigate = useNavigate();

  const [localStorageWalletAddress] = useLocalStorage(
    LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY
  );

  const { connect, connectors, error, isLoading, isError, pendingConnector } =
    useConnect({
      onSuccess: () => {
        navigate("/dashboard", {
          state: {
            onboarding: !localStorageWalletAddress,
          },
        });
      },
    });

  const getInnerContent = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col gap-8 items-center justify-center min-h-[250px]">
          <Spinner size={40} />
          <p>Requesting Connection</p>
        </div>
      );
    }
    if (isError) {
      const errorMapComponentContent = {
        UserRejectedRequestError: (
          <>
            <DescriptiveErrorMessage
              title="Request Cancelled"
              description="You cancelled the request. Click try again!"
            />
            <Button
              className="mt-4"
              onClick={() => {
                connect({ connector: pendingConnector });
              }}
            >
              Try again
            </Button>
          </>
        ),
        ConnectorNotFoundError: (
          <>
            <DescriptiveErrorMessage
              title="Unsupported Browser"
              description={`To connect your ${pendingConnector?.name} wallet, install the extension on Chrome.`}
            />
            <DownloadButton walletName={pendingConnector?.name} />
          </>
        ),
        Error: (
          <>
            <DescriptiveErrorMessage
              title="Something went wrong"
              description="Something went wrong when connecting the wallet. Please try again."
            />
            <Button
              className="mt-4"
              onClick={() => {
                connect({ connector: pendingConnector });
              }}
            >
              Try again
            </Button>
          </>
        ),
      };

      const Icon = walletIconMap[pendingConnector?.id ?? ""];

      console.log("error", error);

      return (
        <div className="flex flex-col gap-6 items-center justify-center min-h-[250px]">
          <p className="text-16 font-bold flex items-center gap-2">
            <span aria-hidden="true">
              {Icon && (
                <div className="w-[24px] h-[24px]">
                  <Icon />
                </div>
              )}
            </span>
            {pendingConnector?.name}
          </p>
          <div className="flex flex-col gap-5">
            {
              errorMapComponentContent[
                error?.name as keyof typeof errorMapComponentContent
              ]
            }
          </div>
        </div>
      );
    }

    return (
      <>
        <h1 className="text-white text-18 font-bold mb-4 text-center">
          Connect Your Wallet
        </h1>
        <p className="text-gray-light mb-4 text-14 text-center px-4">
          To access Milkomeda Validators DApp connect your wallet.
        </p>
        <div className="flex flex-col gap-2">
          {connectors.map((connector) => {
            const Icon = walletIconMap[connector.id ?? ""];

            return (
              <button
                key={connector.name}
                className="flex items-center py-3 px-3 gap-3 justify-between bg-dark-darker rounded-[12px]"
                onClick={() => {
                  connect({ connector });
                }}
              >
                <span aria-hidden="true">
                  {Icon && (
                    <div className="w-[24px] h-[24px]">
                      <Icon />
                    </div>
                  )}
                </span>
                <span className="text-gray-light flex-1 text-left text-14 font-bold">
                  {connector.name}
                </span>
                <RightArrow />
              </button>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className="bg-dark-light p-6 rounded-[12px] border border-border-default max-w-[400px] mx-auto text-white">
      {getInnerContent()}
    </div>
  );
};
export default ConnectWallet;
