import React from "react";
import classNames from "classnames";

import Spinner from "./Spinner";
import { ComponentWithChildren } from "../types/ComponentWithChildren.interface";

interface PanelProps extends ComponentWithChildren {
  title: string;
  withBorder?: boolean;
  counter?: number;
  childrenWrapperClassname?: string;
  isLoading?: boolean;
}

const Panel = ({
  title,
  withBorder = true,
  counter,
  children,
  childrenWrapperClassname,
  isLoading = false,
}: PanelProps) => {
  return (
    <div
      className={classNames(
        "w-full",
        withBorder && "border border-border-default rounded-[12px] p-4"
      )}
    >
      <h2 className="text-white font-bold mb-4 flex items-center gap-1">
        {title}{" "}
        {counter !== undefined && (
          <span className="text-gray-light">({counter})</span>
        )}
        {isLoading && <Spinner size={16} />}
      </h2>
      <div className={childrenWrapperClassname}>{children}</div>
    </div>
  );
};

export default Panel;
