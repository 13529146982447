import React, { useCallback, useEffect, useState } from "react";

import Button from "../Button";
import Modal from "../Modal";
import TextArea from "../TextArea";

import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  LOCAL_STORAGE_APPROVED_REQUESTS_KEY,
  LOCAL_STORAGE_REJECTED_REQUESTS_KEY,
} from "../../constants";
import { ProposalsManager } from "../../proposalsManager";
import { mapNetworkIdWithName, validatorTookAction } from "../../utils/common";

import config from "../../config/config.json";
import { ConfigType } from "../../config/config";
import { usePublicClient, useWalletClient } from "wagmi";
import { RequestInterface } from "../../types/Request.interface";
import { mapActionTypeToActionCode } from "../../utils/proposals";
const cfg = config as ConfigType;
interface TaskActionsProps {
  id: string;
  primaryWalletAddress: string;
  requestData: RequestInterface | undefined;
}

const TaskActions = ({
  id,
  primaryWalletAddress,
  requestData,
}: TaskActionsProps) => {
  const walletClient = useWalletClient();
  const client = usePublicClient();

  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [reason, setReason] = useState("");

  // validator already acted upon the request or not
  const [wasAlreadyActionTaken, setAlreadyActionTaken] = useState(false);

  const [localStorageApprovedRequests, setLocalStorageApprovedRequests] =
    useLocalStorage(LOCAL_STORAGE_APPROVED_REQUESTS_KEY);

  const [localStorageRejectedRequests, setLocalStorageRejectedRequests] =
    useLocalStorage(LOCAL_STORAGE_REJECTED_REQUESTS_KEY);

  const isRequestApprovedInStorage =
    Array.isArray(localStorageApprovedRequests) &&
    localStorageApprovedRequests.includes(id)
      ? true
      : false;

  const isRequestRejectedInStorage =
    Array.isArray(localStorageRejectedRequests) &&
    localStorageRejectedRequests.includes(id)
      ? true
      : false;

  const isRequestActedUpon =
    isRequestApprovedInStorage || isRequestRejectedInStorage;

  const isRequestApprovedByValidator = useCallback((): boolean => {
    return validatorTookAction(
      requestData?.validators ?? [],
      primaryWalletAddress
    );
  }, [requestData, primaryWalletAddress]);

  useEffect(() => {
    const wasAlreadyActionTaken = isRequestApprovedByValidator();
    setAlreadyActionTaken(wasAlreadyActionTaken);
  }, [isRequestApprovedByValidator, requestData]);

  const submitApprovalForProposal = async () => {
    const { message } = ProposalsManager.buildApproveMessage(
      mapActionTypeToActionCode(requestData?.actionType ?? "")
    );

    const secondaryAddress = walletClient.data?.account.address.toString();
    if (!secondaryAddress) {
      throw Error(
        `SecondaryAddress set is ${secondaryAddress}. Reconnect your wallet and reset local storage. Then try again)`
      );
    }

    const txCount = await client.getTransactionCount({
      address: secondaryAddress as any,
    });

    const res = await ProposalsManager.postMessage(
      `0x${id}`,
      message,
      primaryWalletAddress,
      secondaryAddress,
      txCount,
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .validatorsChannelContract
    );

    // TODO: Handle
    console.log(res);

    return;
  };

  const handleApprove = async () => {
    const approvedRequests = Array.isArray(localStorageApprovedRequests)
      ? localStorageApprovedRequests
      : [];

    setLocalStorageApprovedRequests([...approvedRequests, id]);

    // TODO: Handle success and failure
    await submitApprovalForProposal();
  };

  const submitRejectionForProposal = async () => {
    const { message } = ProposalsManager.buildRejectAndCommentMessage(
      reason,
      mapActionTypeToActionCode(requestData?.actionType ?? "")
    );
    const secondaryAddress = walletClient.data?.account.address.toString();
    if (!secondaryAddress) {
      throw Error(
        `SecondaryAddress set is ${secondaryAddress}. Reconnect your wallet and reset local storage. Then try again)`
      );
    }

    const txCount = await client.getTransactionCount({
      address: secondaryAddress as any,
    });
    const res = await ProposalsManager.postMessage(
      id,
      message,
      primaryWalletAddress,
      secondaryAddress,
      txCount,
      cfg[mapNetworkIdWithName(walletClient?.data?.chain.id)]
        .validatorsChannelContract
    );

    // TODO: Handle
    console.log(res);

    return;
  };

  const handleReject = async () => {
    const rejectedRequests = Array.isArray(localStorageRejectedRequests)
      ? localStorageRejectedRequests
      : [];

    setLocalStorageRejectedRequests([...rejectedRequests, id]);

    setRejectModalOpen(false);

    // TODO: Handle success and failure
    await submitRejectionForProposal();
  };

  return (
    <>
      <div className="flex gap-4">
        <Button
          smallVariant
          disabled={wasAlreadyActionTaken || isRequestActedUpon}
          onClick={() => handleApprove()}
        >
          Approve
        </Button>
        <Button
          smallVariant
          outlineVariant
          disabled={wasAlreadyActionTaken || isRequestActedUpon}
          onClick={() => setRejectModalOpen(true)}
        >
          Reject
        </Button>
      </div>
      <Modal isOpen={rejectModalOpen} onClose={() => setRejectModalOpen(false)}>
        <div className="space-y-4">
          <h3 className="text-white font-bold">Reject</h3>
          <TextArea
            placeholder="Describe why this request should be rejected"
            rows={5}
            value={reason}
            onTextAreaChange={(val) => setReason(val)}
          />
          <Button
            onClick={() => {
              handleReject();
            }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TaskActions;
