import React from "react";

import Panel from "../Panel";
import BorderSeparatedList from "../BorderSeparatedList";
import ValidatorVoteTile from "../Validators/ValidatorVoteTile";

import { RequestInterface } from "../../types/Request.interface";

interface RequestValidatorProps {
  validatorsData: RequestInterface["validators"];
}

const RequestValidators = ({ validatorsData }: RequestValidatorProps) => {
  return (
    <Panel
      title="Validators"
      counter={validatorsData?.length}
      isLoading={!validatorsData}
    >
      {!!validatorsData && (
        <BorderSeparatedList md2Columns>
          {validatorsData.map((validator) => (
            <ValidatorVoteTile key={validator.id} {...validator} />
          ))}
        </BorderSeparatedList>
      )}
    </Panel>
  );
};

export default RequestValidators;
