import { createPublicClient, createWalletClient, custom, http } from "viem";

import { ActionCode, ActionType } from "../types/ProposalTypes";
import validatorsChannelAbi from "../abis/ValidatorsChannel.json";
import bridgeContractAbi from "../abis/SidechainBridge.json";

import { chains } from "../constants";
import { ProposalSuccessEnum } from "../types/ProposalSuccess.enum";
import { ethers } from "ethers";

// Algorithm goes:
/// 2 bits version (always 00),
/// 4 bits action type,
/// 4 bits action code,
/// pad with zeroes to 16 bits,

/// convert to hex
export function generateHeader(actionType: ActionType, actionCode: ActionCode) {
  // Convert actionType and actionCode to 4 bits binary strings.
  const actionTypeBinary = actionType.toString(2).padStart(4, "0");
  const actionCodeBinary = actionCode.toString(2).padStart(4, "0");

  // Construct the complete binary string.
  const binaryString = "00" + actionTypeBinary + actionCodeBinary;

  // Pad the binary string to 16 bits with zeroes.
  const paddedBinaryString = binaryString.padEnd(16, "0");

  // Convert the binary string to a hexadecimal string.
  const hexString = parseInt(paddedBinaryString, 2)
    .toString(16)
    .padStart(4, "0");

  return hexString;
}

// export const web3 = new Web3("http://localhost:8545/");
// export const web3 = new Web3(window.ethereum);

// export const validatorsChannel = new web3.eth.Contract(
//   validatorsChannelAbi["abi"],

//   // todo: this needs to be loaded based on the network chosen
//   cfg.localnet.validatorsChannelContract
// ) as any;

export const getValidatorsChannelContract = (
  provider: any,
  contractAddress: string
) => {
  return new ethers.Contract(
    contractAddress,
    validatorsChannelAbi["abi"],
    provider
  );
};

export const getBridgeContract = (provider: any, contractAddress: string) => {
  return new ethers.Contract(
    contractAddress,
    bridgeContractAbi["abi"],
    provider
  );
};

export const getClient = () => {
  // we cannot import the Milkomeda chain from `viem/chains` because it's not supported in the package
  return createPublicClient({
    chain: chains[2],
    transport: http(),
  });
};

export const getWalletClient = (provider: any) => {
  return createWalletClient({
    chain: chains[2],
    transport: custom(provider),
  });
};

export const mapProposalSuccessTypeToTitle: Record<
  ProposalSuccessEnum,
  string
> = {
  [ProposalSuccessEnum.ADD_TOKEN]: "Add Token proposal successfully added",
  [ProposalSuccessEnum.REMOVE_TOKEN]:
    "Remove Token proposal successfully added",
  [ProposalSuccessEnum.L1_TRANSACTION]:
    "L1 Transaction proposal successfully added",
  [ProposalSuccessEnum.L2_TRANSACTION]:
    "L2 Transaction proposal successfully added",
  [ProposalSuccessEnum.CHANGE_VALIDATOR_SET]:
    "Change Validator Set proposal successfully added",
};
export const MAIN_ASSET_ID =
  "0000000000000000000000000000000000000000000000000000000000000000";

export const mapActionTypeToActionCode = (actionType: string): ActionCode => {
  switch (actionType) {
    case "L2_TRANSACTION":
      return ActionCode.RefundL2;
    case "L1_TRANSACTION":
      return ActionCode.Refund;
    case "ADD_TOKEN":
      return ActionCode.TokenRegistryUpdate;
    case "REMOVE_TOKEN":
      return ActionCode.TokenRegistryUpdate;
    case "CHANGE_VALIDATOR_SET":
      return ActionCode.MoveStargateFunds;
    default:
      throw Error(`Couldn't map ${actionType} with any Action code found`);
  }
};
