import React from "react";

import StatusTile, { StatusTileProps } from "../StatusTile";

interface TaskOverviewTileProps extends StatusTileProps {
  approvedCount: number;
  rejectCount: number;
  validatorsCount: number;
  quorumCount: number;
}

const TaskOverviewTile = ({
  approvedCount,
  rejectCount,
  indicativeStatus,
  validatorsCount,
  quorumCount,
  actionStatus,
  actionType,
}: TaskOverviewTileProps) => {
  return (
    <div className="border border-border-default rounded-[12px] bg-dark-light p-3 flex flex-col md:flex-row md:items-center gap-4">
      <div>
        <StatusTile
          actionType={actionType}
          actionStatus={actionStatus}
          indicativeStatus={indicativeStatus}
        />
      </div>
      <div className="text-14 font-medium text-gray-light flex flex-col gap-[6px] w-full">
        <p className="flex items-center justify-between text-12">
          Approved (quorum {quorumCount}):
          <span className="text-green text-14">{approvedCount}</span>
        </p>
        <p className="flex items-center justify-between text-12">
          Rejected:{" "}
          <span className="text-red-default text-14">{rejectCount}</span>
        </p>
        <p className="flex items-center justify-between text-12">
          Number of Validators:
          <span className="text-white text-14">{validatorsCount}</span>
        </p>
      </div>
    </div>
  );
};

export default TaskOverviewTile;
