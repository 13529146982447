import React from "react";
import { useRouteError } from "react-router-dom";

import Button from "./Button";

const ErrorDisplay = () => {
  const error = useRouteError();

  const handleReloadClick = () => {
    window.location.reload();
  };

  return (
    <div className="bg-dark-default">
      <div className="container text-white pt-10 max-w-[800px]">
        <h1 className="text-24 text-center">Uh-oh! Something Went Wrong</h1>
        <p className="text-gray-light text-center mt-4">
          We're sorry, but it seems like there's a hiccup in the crypto cosmos
          right now. Our digital treasure hunters are working hard to bring
          things back in balance. Here's what we know so far:
        </p>
        {error instanceof Error && (
          <div className="mt-10">
            <div className="mt-4 mb-8">
              <h2 className="text-gray-light">Message:</h2>
              <p className="bg-dark-light p-4 border border-red-default rounded-[12px] mt-2 font-mono text-14">
                {error.message}
              </p>
            </div>
            <div className="mt-4 mb-8">
              <h2 className="text-gray-light">Stack:</h2>
              <p className="bg-dark-light p-4 border border-red-default rounded-[12px] mt-2 font-mono text-14">
                {error.stack}
              </p>
            </div>
          </div>
        )}
        <Button onClick={handleReloadClick}>Reload</Button>
      </div>
    </div>
  );
};

export default ErrorDisplay;
