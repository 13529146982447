import { pad } from "viem";
// import { encode } from "cbor-web";
import { encode } from "cbor-x";
import { padRight } from "web3-utils";

import {
  ActionCode,
  ActionType,
  L1TxInterface,
  L2TxInterface,
  TokenRegistryInterface,
  ValidatorChangeTxInterface,
} from "./types/ProposalTypes";
import {
  generateHeader,
  getBridgeContract,
  getValidatorsChannelContract,
} from "./utils/proposals";
import { ethers } from "ethers";

export class ProposalsManager {
  public static async postMessage(
    id: string,
    message: Buffer,
    primaryAddress: string,
    secondaryAddress: string,
    txCount: number,
    contractAddress: string
  ): Promise<string | null> {
    try {
      // TODO: for some reason `simulateContract` doesn't allow to pass unknown bytes as contract parameter to `bytes`
      // In here: https://github.com/wagmi-dev/viem/blob/e4a870e7d5bc402452ca175e1e896435bfc3478a/src/utils/abi/encodeAbiParameters.ts#L199-L210
      // viem tries to treat bytes as bytes32, where bytes is different format...
      // before this one is resolved, for further processings, we're going to use web3js lib which works fine with cli scripts
      // const { result } = await client.simulateContract({
      //   address: cfg.localnet.validatorsChannelContract as any,
      //   abi: validatorsChannelAbi["abi"] as any,
      //   functionName: "emitMessageAlt",
      //   args: [id, message, primaryAddress],
      //   account: secondaryAddress,
      //   nonce: txCount,
      // });
      // const provider = new Web3.providers.HttpProvider(
      //   chains[0].rpcUrls.default.http[0]
      // );

      let provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = await provider.getSigner();

      const contract = getValidatorsChannelContract(provider, contractAddress);
      const contractWithSigner = contract.connect(signer) as any;

      if (!id.startsWith("0x")) {
        id = "0x" + id;
      }

      try {
        const txResponse = await contractWithSigner.emitMessageAlt(
          id,
          "0x" + message.toString("hex"),
          primaryAddress,
          // TODO: set something smaller here, though leaving this one as is for initial testing
          { gasLimit: ethers.utils.hexlify(100000) }
        );

        console.log("Transaction Hash:", txResponse.hash);

        // const receipt = await txResponse.wait();
        // console.log("Transaction was confirmed in block:", receipt.blockNumber);

        return txResponse.hash;
      } catch (error) {
        console.error("Error sending transaction", error);
      }
    } catch (e) {
      const err = e as Error;
      console.error(err);
    }
    return null;
  }

  public static buildTRMessage(
    initialProposalTokenRegistryUpdate: TokenRegistryInterface
  ): { id: string; message: Buffer } {
    try {
      const header = generateHeader(
        ActionType.TokenRegistryUpdateInit,
        ActionCode.TokenRegistryUpdate
      );
      const headerBuffer = Buffer.from(header, "hex");

      // initial proposal id is padded 0s initially, since tx id is generated when we post stuff on chain
      const id = pad("0x", {
        dir: "right",
      });

      const fullMessagePayload = {
        id,
        initialProposalTokenRegistryUpdate,
      };
      console.log(`Message in JSON: ${JSON.stringify(fullMessagePayload)}`);

      const bodyCbor = encode(initialProposalTokenRegistryUpdate);

      const message = Buffer.concat([headerBuffer, bodyCbor]);
      console.log(
        `Full message (header & message) in cbor: ${message.toString("hex")}`
      );

      return { id, message };
    } catch (e) {
      console.error(e);
      return {
        id: "",
        message: Buffer.from("0"),
      };
    }
  }

  public static buildL2TxMessage(initialProposalL2Transaction: L2TxInterface): {
    id: string;
    message: Buffer;
  } {
    const header = generateHeader(ActionType.L2TxInit, ActionCode.RefundL2);
    const headerBuffer = Buffer.from(header, "hex");

    // initial proposal id is padded 0s initially, since tx id is generated when we post stuff on chain
    const id = padRight(0, 64);

    const fullMessagePayload = {
      id,
      initialProposalL2Transaction,
    };
    console.log(`Message in JSON: ${JSON.stringify(fullMessagePayload)}`);

    const bodyCbor = encode(initialProposalL2Transaction);
    console.log(`Message body in cbor: ${bodyCbor.toString("hex")}`);

    const message = Buffer.concat([headerBuffer, bodyCbor]);
    console.log(
      `Full message (header & message) in cbor: ${message.toString("hex")}`
    );

    return { id, message };
  }

  public static buildL1TxMessage(initialProposalL1Transaction: L1TxInterface): {
    id: string;
    message: Buffer;
  } {
    const header = generateHeader(ActionType.L1TxInit, ActionCode.Refund);
    const headerBuffer = Buffer.from(header, "hex");

    // initial proposal id is padded 0s initially, since tx id is generated when we post stuff on chain
    const id = padRight(0, 64);

    const fullMessagePayload = {
      id,
      initialProposalL1Transaction,
    };
    console.log(`Message in JSON: ${JSON.stringify(fullMessagePayload)}`);

    const bodyCbor = encode(initialProposalL1Transaction);
    console.log(`Message body in cbor: ${bodyCbor.toString("hex")}`);

    const message = Buffer.concat([headerBuffer, bodyCbor]);
    console.log(
      `Full message (header & message) in cbor: ${message.toString("hex")}`
    );

    return { id, message };
  }

  public static buildApproveMessage(actionCode: ActionCode): {
    message: Buffer;
  } {
    // APPROVE is basically almost an empty message, but requires specific format to be compatible with the bridge serialization
    const header = generateHeader(ActionType.Approve, actionCode);
    const headerBuffer = Buffer.from(header, "hex");

    const bodyCbor = encode(null);
    console.log(`Body: ${bodyCbor.toString("hex")}`);

    const message = Buffer.concat([headerBuffer, bodyCbor]);

    return { message };
  }

  public static buildRejectAndCommentMessage(
    reason: string,
    actionCodeForRejection: ActionCode
  ): {
    message: Buffer;
  } {
    const header = generateHeader(
      ActionType.RejectAndComment,
      actionCodeForRejection
    );
    const headerBuffer = Buffer.from(header, "hex");

    const rejectAndCommentMessage = {
      reason,
    };
    const bodyCbor = encode(rejectAndCommentMessage);
    console.log(`Body: ${bodyCbor.toString("hex")}`);

    const message = Buffer.concat([headerBuffer, bodyCbor]);
    console.log(`Message (header & message): ${message.toString("hex")}`);

    return { message };
  }

  public static buildValidatorsChangeMessage(
    initialProposalValidatorsChange: ValidatorChangeTxInterface
  ): {
    id: string;
    message: Buffer;
  } {
    const header = generateHeader(
      ActionType.ValidatorUpdateProposal,
      ActionCode.MoveStargateFunds
    );
    const headerBuffer = Buffer.from(header, "hex");

    // initial proposal id is padded 0s initially, since tx id is generated when we post stuff on chain
    const id = padRight(0, 64);

    const fullMessagePayload = {
      id,
      initialProposalValidatorsChange,
    };
    console.log(`Message in JSON: ${JSON.stringify(fullMessagePayload)}`);

    const bodyCbor = encode(initialProposalValidatorsChange);
    console.log(`Message body in cbor: ${bodyCbor.toString("hex")}`);

    const message = Buffer.concat([headerBuffer, bodyCbor]);
    console.log(
      `Full message (header & message) in cbor: ${message.toString("hex")}`
    );

    return { id, message };
  }

  public static async isValidator(
    validatorsContractAddress: string,
    bridgeContractAddress: string,
    currentLoggedAddress: string
  ): Promise<boolean> {
    let provider = new ethers.providers.Web3Provider(window.ethereum);

    const bridgeContract = getBridgeContract(provider, bridgeContractAddress);

    try {
      const validatorsList = await bridgeContract.getValidators();
      for (const validator of validatorsList) {
        const validatorsContract = getValidatorsChannelContract(
          provider,
          validatorsContractAddress
        );
        const secondaryAddressFromVC =
          (await validatorsContract.secondaryAddresses(validator)) as string;

        if (
          secondaryAddressFromVC === undefined ||
          secondaryAddressFromVC === null ||
          secondaryAddressFromVC ===
            "0x0000000000000000000000000000000000000000" ||
          currentLoggedAddress.toUpperCase() !==
            secondaryAddressFromVC.toUpperCase()
        ) {
          // console.log(`Missing secondary address mapping for ${validator}`);
          // return false;
        } else {
          console.log(
            `Logged in: ${currentLoggedAddress}; Secondary: ${secondaryAddressFromVC}`
          );
          if (
            currentLoggedAddress.toUpperCase() ===
            secondaryAddressFromVC.toUpperCase()
          ) {
            console.log("Logged in user is a validator");
            return true;
          }
        }
      }
      return false;
    } catch (error) {
      console.error("Error sending transaction. ", error);
    }
    return false;
  }
}
