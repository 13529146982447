import React, { useState } from "react";

import Layout from "../components/Layout";
import Panel from "../components/Panel";
import RequestRow from "../components/Requests/RequestRow";
import SearchInput from "../components/SearchInput";
import TextDropdown, { TextDropdownProps } from "../components/TextDropdown";
import Pagination from "../components/Pagination";
import NoRequestsMatchCriteria from "../components/NoRequestsMatchCriteria";
import PageHeader from "../components/PageHeader";

import { ActionStatus } from "../types/ActionStatus.enum";
import { ActionType } from "../types/ActionType.enum";
import {
  mapActionStatusToMessage,
  mapActionTypeToMessage,
} from "../utils/requests";
import { useFilteredRequests } from "../hooks/useFilteredRequests";
import { useDebounce } from "../hooks/useDebounce";

const typeFilterOptions: Pick<TextDropdownProps, "options"> = {
  options: [
    {
      label: "All types",
      id: "all",
    },
    ...Object.values(ActionType).map((type) => ({
      label: mapActionTypeToMessage[type],
      id: type,
    })),
  ],
};

const statusFilterOptions: Pick<TextDropdownProps, "options"> = {
  options: [
    {
      label: "All statuses",
      id: "all",
    },
    ...Object.values(ActionStatus).map((status) => ({
      label: mapActionStatusToMessage[status],
      id: status,
    })),
  ],
};

const PER_PAGE = 10;

const AllRequests = () => {
  const [currentLocalPage, setCurrentLocalPage] = useState(1);

  const [typeFilter, setTypeFilter] = useState(typeFilterOptions.options[0]);
  const [statusFilter, setStatusFilter] = useState(
    statusFilterOptions.options[0]
  );

  const [txIdFilter, setTxIdFilter] = useState<string | undefined>(undefined);
  const debouncedTxIdFilter = useDebounce<string | undefined>(txIdFilter, 500);

  const { maxPage, currentPage, requests, allCount, isLoading } =
    useFilteredRequests(
      PER_PAGE,
      currentLocalPage,
      statusFilter.id === "all" ? null : (statusFilter.id as ActionStatus),
      typeFilter.id === "all" ? null : (typeFilter.id as ActionType),
      undefined,
      debouncedTxIdFilter
    );

  return (
    <Layout title="All Requests">
      <section className="bg-dark-default pt-6 pb-16 relative overflow-y-hidden full-window-height flex flex-col">
        <PageHeader
          title="All Requests"
          description="All validators proposals"
        />
        <div className="container z-10 relative">
          <div className="mt-8 flex flex-col-reverse gap-6 lg:gap-4 lg:flex-row">
            <Panel
              withBorder={false}
              title="All Requests"
              counter={allCount}
              childrenWrapperClassname="space-y-3"
              isLoading={isLoading}
            >
              <div className="flex flex-col gap-3 md:flex-row">
                <div className="basis-full lg:basis-1/2">
                  <SearchInput
                    placeholder="Tx ID..."
                    onSearchChange={(v) => setTxIdFilter(v)}
                  />
                </div>
                <div className="basis-full lg:basis-1/4">
                  <TextDropdown
                    {...typeFilterOptions}
                    selectedOption={typeFilter}
                    optionLabel="Type:"
                    onOptionChange={(o) => setTypeFilter(o)}
                  />
                </div>
                <div className="basis-full lg:basis-1/4">
                  <TextDropdown
                    {...statusFilterOptions}
                    selectedOption={statusFilter}
                    optionLabel="Status:"
                    onOptionChange={(o) => setStatusFilter(o)}
                  />
                </div>
              </div>
              {requests.map((request) => {
                return (
                  <RequestRow
                    key={request.id}
                    {...request}
                    detailLink={`/requests/${request.id}`}
                  />
                );
              })}
              {allCount === 0 && !isLoading && <NoRequestsMatchCriteria />}
              <div className="flex justify-end">
                <Pagination
                  currentPage={currentPage}
                  maxPage={maxPage}
                  onPageClick={(page) => setCurrentLocalPage(page)}
                />
              </div>
            </Panel>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AllRequests;
