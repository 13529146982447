import { IconComponentType } from "../types/IconComponent.type";

import { ReactComponent as TwitterIcon } from "../assets/icon-twitter.svg";
import { ReactComponent as DiscordIcon } from "../assets/icon-discord.svg";
import { ReactComponent as GitbookIcon } from "../assets/icon-gitbook.svg";

interface SocialLink {
  href: string;
  label: string;
  iconComponent: IconComponentType;
}

interface MenuItem {
  href: string;
  label: string;
}

const socialLinks: SocialLink[] = [
  {
    href: "https://twitter.com/Milkomeda_com",
    label: "twitter",
    iconComponent: TwitterIcon,
  },
  {
    href: "https://discord.com/invite/dcSpark",
    label: "discord",
    iconComponent: DiscordIcon,
  },
  {
    href: "https://dcspark.github.io/milkomeda-documentation/cardano/guides/Using-MACC",
    label: "gitbook",
    iconComponent: GitbookIcon,
  },
];

const menuItems: MenuItem[] = [
  {
    href: "https://www.milkomeda.com/",
    label: "About Milkomeda",
  },
  {
    href: "https://www.milkomeda.com/terms-of-use",
    label: "Terms of Use",
  },
];

const Footer = () => {
  return (
    <footer className="py-8 lg:py-3 px-8 bg-dark-default flex flex-col lg:flex-row items-center border-t border-t-border-default space-y-8 lg:space-y-0">
      <p className="text-gray-light text-14 font-medium flex-1 text-left">
        © {new Date().getFullYear()} Milkomeda
      </p>
      <ul className="flex items-center justify-center space-x-4 flex-1">
        {socialLinks.map((social, index) => {
          const Icon = social.iconComponent;

          return (
            <li key={index}>
              <a
                href={social.href}
                className="text-social hover:text-orange-default"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only">{social.label}</span>
                <Icon className="fill-current" />
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="flex items-center space-y-2 lg:space-x-4 lg:space-y-0 flex-1 justify-end flex-col lg:flex-row">
        {menuItems.map((link, index) => (
          <li key={index}>
            <a
              href={link.href}
              className="text-gray-light text-14 font-medium hover:text-white hover:underline cursor-pointer"
              target="_blank"
              rel="noreferrer"
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
