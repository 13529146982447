import { Chain } from "wagmi";

import { ReactComponent as MetamaskIcon } from "../src/assets/icon-metamask.svg";
import { ReactComponent as WalletConnectIcon } from "../src/assets/icon-walletconnect.svg";

export const walletIconMap: Record<string, React.FC> = {
  metaMask: MetamaskIcon,
  walletConnect: WalletConnectIcon,
  // [Connector.WalletConnectV1]: WalletConnectIcon,
};

export const walletNameMap = {
  metaMask: "MetaMask",
  // [Connector.WalletConnectV1]: "Wallet Connect",
};

export const chains: Chain[] = [
  {
    id: 1000,
    name: "Milkomeda C1 Localnet",
    network: "Milkomeda C1 Localnet",
    nativeCurrency: {
      name: "milkTADA",
      symbol: "mTADA",
      decimals: 18,
    },
    rpcUrls: {
      public: { http: ["http://localhost:8545"] },
      default: { http: ["http://localhost:8545"] },
    },
    blockExplorers: {
      etherscan: { name: "", url: "" },
      default: {
        name: "",
        url: "https://explorer-devnet-cardano-evm.c1.milkomeda.com",
      },
    },
  },
  {
    id: 2001,
    name: "Milkomeda C1 Mainnet",
    network: "Milkomeda C1 Mainnet",
    nativeCurrency: {
      name: "milkADA",
      symbol: "mADA",
      decimals: 18,
    },
    rpcUrls: {
      public: { http: ["https://rpc-mainnet-cardano-evm.c1.milkomeda.com"] },
      default: { http: ["https://rpc-mainnet-cardano-evm.c1.milkomeda.com"] },
    },
    blockExplorers: {
      etherscan: { name: "", url: "" },
      default: {
        name: "",
        url: "https://explorer-mainnet-cardano-evm.c1.milkomeda.com",
      },
    },
  },
  {
    id: 200101,
    name: "Milkomeda C1 Devnet",
    network: "Milkomeda C1 Devnet",
    nativeCurrency: {
      name: "milkTADA",
      symbol: "mTADA",
      decimals: 18,
    },
    rpcUrls: {
      public: { http: ["https://rpc-devnet-cardano-evm.c1.milkomeda.com"] },
      default: { http: ["https://rpc-devnet-cardano-evm.c1.milkomeda.com"] },
    },
    blockExplorers: {
      etherscan: { name: "", url: "" },
      default: {
        name: "",
        url: "https://explorer-devnet-cardano-evm.c1.milkomeda.com",
      },
    },
  },
];

export const LOCAL_STORAGE_PRIMARY_WALLET_ADDRESS_KEY =
  "milk_validators_primary_wallet_address";

export const LOCAL_STORAGE_APPROVED_REQUESTS_KEY =
  "milk_validators_approved_requests";

export const LOCAL_STORAGE_REJECTED_REQUESTS_KEY =
  "milk_validators_rejected_requests";

export const TOKENS_ASSET_ID_BLACKLIST = [
  "0000000000000000000000000000000000000000000000000000000000000000",
];

export const SUPPORTED_CHAIN_ID = 200101;
