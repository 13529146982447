import React, { ReactElement, ReactNode, useId } from "react";
import { Tooltip } from "react-tooltip";

import { ReactComponent as QuestionIcon } from "../../assets/icon-question.svg";
import classNames from "classnames";

interface RequestDetailRowProps {
  title: string;
  tooltip: string;
  value: string | ReactElement | ReactElement[] | ReactNode | ReactNode[];
  boldValue?: boolean;
}

const RequestDetailRow = ({
  title,
  tooltip,
  value,
  boldValue = false,
}: RequestDetailRowProps) => {
  const id = useId();

  return (
    <div className="flex flex-col md:flex-row gap-1 md:gap-2 py-1">
      <h2 className="flex items-start gap-1 text-gray-light fill-gray-light text-14 md:basis-[200px]">
        <Tooltip id={`${id}-tooltip`} />
        <div
          data-tooltip-id={`${id}-tooltip`}
          data-tooltip-content={tooltip}
          data-tooltip-place="top"
        >
          <div className="w-[16px] h-[16px] pt-[2px]">
            <QuestionIcon />
          </div>
        </div>
        {title}
      </h2>
      <div className="md:basis-[70%]">
        {typeof value === "string" ? (
          <p
            className={classNames(
              "text-14 text-white break-all",
              boldValue && "font-bold"
            )}
          >
            {value}
          </p>
        ) : (
          <div
            className={classNames(
              "text-14 text-white",
              boldValue && "font-bold"
            )}
          >
            {value}
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestDetailRow;
